import {camelCase, chain, isArray, isObject, map} from 'lodash';
import {isBinary} from 'src/app/domain/function/form-data.helper';
import {Fn} from 'src/app/domain/function/type.helper';
import {snakeCase} from './string.helper';

// @ts-ignore
export const objToCamelCase = <T>(obj: any): T => chain(obj)
  .mapKeys((value, key: string) => camelCase(key))
  .mapValues((value) => {
    if (isBinary(value)) {
      return value;
    }
    if (isArray(value)) {
      return objsToCamelCase(value);
    }
    if (isObject(value)) {
      return objToCamelCase(value);
    }
    return value;
  })
  .value();

export const objsToCamelCase = <T>(objs: any[]): T[] => {
  if (isArray(objs)) {
    return map(objs, (obj) => objToCamelCase(obj));
  }
  if (isObject(objs)) {
    return objToCamelCase(objs);
  }
  return objs;
};

// @ts-ignore
export const objKeysToSnakeCase = <T>(objs: any): T => chain(objs)
  .mapKeys((value, key: string) => snakeCase(key))
  .mapValues(
    (value) => {
      if (isBinary(value)) {
        return value;
      }
      if (isArray(value)) {
        return map(value, (obj) => objKeysToSnakeCase(obj));
      }
      if (isObject(value)) {
        return objKeysToSnakeCase(value);
      }
      return value;
    }
  )
  .value();

export const objsToSnakeCase = <T>(objs: any[]): T[] => {
  if (isArray(objs)) {
    return objKeysToSnakeCase(objs);
  }
  if (isObject(objs)) {
    return objToCamelCase(objs);
  }
  return objs;
};

export const objectMap = <T extends object, R extends object>(obj: T, fn: Fn<any, [any, (string|number)?, number?]>): R =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])) as R;

export const objectFilter = <T extends object, R extends object>(obj: T, fn: Fn<any, [any, (string|number)?, number?]>): R =>
  Object.fromEntries(Object.entries(obj).filter(([k, v], i) => fn(v, k, i))) as R;

export const objectIsEmpty = <T>(obj: object): boolean => Object.keys(obj).length === 0;
