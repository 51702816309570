import {User} from '../model/user.model';
import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {isEqual} from 'lodash';
import {Localstorage} from '../service/storage/localstorage';

export interface UserState {
  me: User | null;
}

export const USER_NAMESPACE = '__me__';

const emptyState = () => ({
  me: null,
});

@Injectable({
  providedIn: 'root',
})
export default class UserStore extends ComponentStore<UserState> {
  public readonly me$ = this.select(state => state.me);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveAuthUser(me: User): void {
    if (isEqual(this.syncUser(), me)) {
      return;
    }

    this.patchState(() => ({
      me
    }));
    this.localStorage.syncSet(USER_NAMESPACE, JSON.stringify(me));
  }

  syncUser(): User | null {
    return this.get().me;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
    this.localStorage.unset(USER_NAMESPACE);
  }
}
