import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';

export type AuthHttpInterceptorState = {
  isValidAccessToken: boolean;
  submittingRefreshToken: boolean;
  refreshTokenRetries: number;
};

const emptyState = (): AuthHttpInterceptorState => ({
  isValidAccessToken: true,
  submittingRefreshToken: false,
  refreshTokenRetries: 0
});

@Injectable({
  providedIn: 'root'
})
export default class AuthHttpInterceptorStateStore extends ComponentStore<AuthHttpInterceptorState> {
  public readonly state$ = this.select(state => state);

  constructor() {
    super(emptyState());
  }

  save(state: AuthHttpInterceptorState): void {
    this.patchState(() => (state));
  }

  sync(): AuthHttpInterceptorState {
    return this.get();
  }

  reset(): void {
    this.patchState(() => ({...emptyState()}));
  }
}
