import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {AuthSignature} from '../model/auth-signature/auth-signature.model';
import {Localstorage} from 'src/app/domain/service/storage/localstorage';

export interface AuthSignatureState {
  authSignature: AuthSignature | null;
}

export const AUTHSIGNATURE_NAMESPACE = '__signaturit__';

const emptyState = () => ({
  authSignature: null,
});

@Injectable({
  providedIn: 'root',
})
export default class AuthSignatureStore extends ComponentStore<AuthSignatureState> {
  public readonly authSignature$ = this.select(state => state.authSignature);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveAuthSignature(authSignature: AuthSignature): void {
    this.patchState(() => ({
      authSignature
    }));

    this.localStorage.syncSet(AUTHSIGNATURE_NAMESPACE, JSON.stringify(authSignature));
  }

  syncAuthSignature(): AuthSignature | null {
    return this.get().authSignature;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
    this.localStorage.unset(AUTHSIGNATURE_NAMESPACE);
  }
}
