import {isNil} from 'lodash';
import {ErrorCode, ErrorMapping} from 'src/app/domain/error/error-codes';
import {getTranslatableMsg, getTranslatableParams, Translatable} from 'src/app/domain/service/translator/translatable';

export default class ValidationError extends Error {
  constructor(
    public readonly code: ErrorCode,
    public readonly message: string,
    public readonly params: { [k: string]: any } = {}
  ) {
    super();
  }

  translatable(errorMapping: ErrorMapping = {}): Translatable {
    const mapped: Translatable = errorMapping[this.code];
    return isNil(mapped)
      ? [this.message, this.params]
      : [getTranslatableMsg(mapped), {...this.params, ...getTranslatableParams(mapped)}];
  }
}
