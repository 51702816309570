export const PRODUCT_TYPES = {
  investment: 'investment',
  unitLinked: 'unit_linked',
  pensionPlan: 'pension_plan'
} as const;

export const PRODUCT_IDS = {
  inversisInvestment: `inversis_${PRODUCT_TYPES.investment}`,
  caserPensionPlan: `caser_${PRODUCT_TYPES.pensionPlan}`,
  caserUnitLinked: `caser_${PRODUCT_TYPES.unitLinked}`,
  evoCaserUnitLinked: `evo_caser_${PRODUCT_TYPES.unitLinked}`,
  ahorrobotCaserUnitLinked: `ahorrobot_caser_${PRODUCT_TYPES.unitLinked}`,
} as const;

export type ProductId = typeof PRODUCT_IDS[keyof typeof PRODUCT_IDS];
export type ProductType = typeof PRODUCT_TYPES[keyof typeof PRODUCT_TYPES];

export const isValid = (product: ProductId): boolean => Object.values(PRODUCT_IDS).includes(product);
export const isInvestmentPlan = (product: ProductId | ProductType): boolean => product?.endsWith(PRODUCT_TYPES.investment) || false;
export const isUnitLinkedPlan = (product: ProductId | ProductType): boolean => product?.endsWith(PRODUCT_TYPES.unitLinked) || false;
export const isPensionPlan = (product: ProductId | ProductType): boolean => product?.endsWith(PRODUCT_TYPES.pensionPlan) || false;

export const getProductType = (product: ProductId | ProductType): ProductType => {
  switch (true) {
    case isInvestmentPlan(product):
      return PRODUCT_TYPES.investment;
    case isPensionPlan(product):
      return PRODUCT_TYPES.pensionPlan;
    case isUnitLinkedPlan(product):
      return PRODUCT_TYPES.unitLinked;
  }
  throw new Error('Invalid product');
};
