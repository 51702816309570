import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {isNil} from 'lodash';

export type ToastPosition = 'top' | 'middle' | 'bottom';
export type ToastButtonRole = 'top' | 'middle' | 'bottom';

export type ToastButton = {
  text: string;
  role: 'cancel' | string;
};

export interface ToastOptions<T> {
  message: string;
  position?: ToastPosition;
  duration: number;
  buttons: ToastButton[];
};

@Injectable({
  providedIn: 'root',
})
export class Toast {
  private toast: HTMLIonToastElement | null;

  constructor(private readonly toastCtrl: ToastController) {
  }

  async present<T>(options: ToastOptions<T>): Promise<HTMLIonToastElement> {
    this.toast = null;
    const toast = await this.toastCtrl.create(options as any);
    await toast.present();
    this.toast = toast;
    return toast;
  }

  isOpen(): boolean {
    return !isNil(this.toast);
  }

  async dismiss(data?: any) {
    await this.toastCtrl.dismiss(data);
  }
}
