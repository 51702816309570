import {Location} from '@angular/common';
import {Injectable} from '@angular/core';

import {ActivatedRoute, NavigationEnd, NavigationExtras, Router as NgRouter} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {scrollToTop} from 'src/app/domain/function/scroll.helper';
import StyleStore from 'src/app/infrastructure/store/style.store';

export interface Route {
  url: string;
}

export interface ExtraNavigate extends NavigationExtras {
  scrollTop?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class Router {
  constructor(
    private router: NgRouter,
    private location: Location,
    private route: ActivatedRoute,
    private styleStore: StyleStore,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        const extras = this.router.getCurrentNavigation().extras as ExtraNavigate;
        extras.scrollTop ??= true;
        extras.scrollTop && scrollToTop();
        this.styleStore.reset();
        console.log(`Router event: ${event.constructor.name}`);
        console.log(event);
        console.groupEnd();
      });
  }

  public navigate(commands: any[], extras?: ExtraNavigate): Promise<boolean> {
    extras = {scrollTop: true, ...(extras ?? {})};
    return this.router.navigate(commands, extras);
  }

  public navigateByUrl(url: string, extras?: ExtraNavigate): Promise<boolean> {
    extras = {scrollTop: true, ...(extras ?? {})};
    return this.router.navigateByUrl(url, extras);
  }

  public changeUrl(route: string) {
    this.location.replaceState(route);
  }

  public currentUrl(): Route {
    return {url: this.router.url};
  }

  public navigateToBack(): void {
    return this.location.back();
  }

  public navigateToNotFound(): void {
    this.navigate(['404']);
  }

  public navigationStart(): Observable<Route> {
    return this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => {
        const route:  Route = {
          url: this.router.url
        };
        return route;
      })
    );
  }

  public changed(): Observable<Route> {
    return this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => {
        const route: Route = {
          url: this.router.url
        };
        return route;
      }),
    );
  }

  get(key: string, activatedRoute?: ActivatedRoute) {
    activatedRoute ??= this.route;
    return activatedRoute.snapshot.queryParamMap.get(key) ?? activatedRoute.snapshot.paramMap.get(key);
  }
}
