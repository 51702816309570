import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {AppVersion} from 'src/app/domain/model/app/app-version.model';

export interface AppVersionState {
  appVersion: AppVersion | null;
}

const emptyState = () => ({
  appVersion: null,
});

@Injectable({
  providedIn: 'root',
})
export default class AppVersionStore extends ComponentStore<AppVersionState> {
  public readonly appVersion$ = this.select(state => state.appVersion);

  constructor() {
    super(emptyState());
  }

  public save(appVersion: AppVersion): void {
    this.patchState(() => ({
      appVersion
    }));
  }

  public syncAppVersion(): AppVersion | null {
    return this.get().appVersion;
  }

  public reset(): void {
    this.setState(() => (emptyState()));
  }
}
