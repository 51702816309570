import {Injectable} from '@angular/core';
import {isNil} from 'lodash';
import {isCanceledPortfolio, isEnabledPortfolio, isInvestmentPortfolio} from 'src/app/domain/model/customer-portfolio.model';
import {Customer, hasOperationalBlockReasons} from 'src/app/domain/model/customer/customer.model';
import CustomerPortfolioApi from 'src/app/domain/service/api/customer-portfolio.api';
import CustomerApi from 'src/app/domain/service/api/customer/customer.api';

@Injectable({
  providedIn: 'root'
})

export default class CustomerAreaRouteResolver {
  private readonly rootUrl: string = '/client';

  constructor(
    private portfolioApi: CustomerPortfolioApi,
    private customerApi: CustomerApi
  ) {
  }

  public resolveRoot(): string {
    return this.rootUrl;
  }

  public resolveKyc(): string {
    return this.rootUrl + '/profile/kyc';
  }

  public resolveIban(): string {
    return this.rootUrl + '/portfolio-configuration/iban';
  }

  public resolveCancellation(): string {
    return this.rootUrl + '/portfolio/cancellation';
  }

  public resolveChangePortfolio(): string {
    return this.rootUrl + '/portfolio-configuration/change';
  }

  public resolveAcceptPortfolio(): string {
    return this.rootUrl + '/portfolio-configuration/change/change-contract';
  }

  public resolveAllPortfolios(): string {
    return this.rootUrl + '/all-portfolios';
  }

  public resolveFeePage(): string {
    const selectedPortfolio = this.portfolioApi.syncSelected();
    if (isInvestmentPortfolio(selectedPortfolio)) {
      return this.rootUrl + '/portfolio/investment/fees';
    }
    return this.rootUrl + '/portfolio/fees';
  }

  public resolvePortfolioContracted(): string {
    const portfolios = this.portfolioApi.syncAll();
    if (portfolios.length > 1) {
      return this.resolveAllPortfolios();
    }
    return this.resolveCustomerArea();
  }

  public resolveCustomerArea(): string {
    const selectedPortfolio = this.portfolioApi.syncSelected();

    if (isNil(selectedPortfolio)) {
      return this.rootUrl;
    }

    if (isCanceledPortfolio(selectedPortfolio)) {
      return this.rootUrl + '/cancelled';
    }

    if (!isEnabledPortfolio(selectedPortfolio)) {
      return this.rootUrl + '/portfolio/activation';
    }

    return this.rootUrl;
  }

  public resolveEditPassword(): string {
    return this.rootUrl + '/profile/password';
  }

  public resolveChangeRiskProfileWarning(): string {
    return this.rootUrl + '/profile/kyc/warning';
  }

  public resolveChangeRiskProfile(): string {
    return this.rootUrl + '/profile/kyc';
  }

  public resolvePasswordDone(): string {
    return this.rootUrl + '/profile/password/done';
  }

  public resolveConfigurationKycQuestions(): string {
    return this.rootUrl + '/profile/show-results';
  }

  public resolveAcceptKycContract(): string {
    return this.rootUrl + '/profile/accept-contract-kyc';
  }

  public resolveConfigurationData(): string {
    return this.rootUrl + '/portfolio-configuration/data';
  }

  public resolveIbanPhoto(): string {
    return this.rootUrl + '/portfolio-configuration/iban/photo';
  }

  public resolveIbanSepa(): string {
    return this.rootUrl + '/portfolio-configuration/iban/sepa';
  }

  public profileConfiguration(): string {
    return this.rootUrl + '/profile/configuration';
  }

  public resolveChangePassword() {
    return this.rootUrl + '/profile/configuration';
  }

  public resolveProfile(): string {
    const customer: Customer = this.customerApi.syncCustomer();

    if (hasOperationalBlockReasons(customer)) {
      return '/operational-block/customer-data';
    }
    return this.rootUrl + '/profile';
  }

  public resolveMoneyWeightedMoreInfo() {
    return this.rootUrl + '/portfolio/performance/money-weighted';
  }

  public resolveTimeWeightedMoreInfo() {
    return this.rootUrl + '/portfolio/performance/time-weighted';
  }

  public resolvePortfolioDetail() {
    return this.rootUrl + '/portfolio/detail';
  }

  public resolveLastMovements() {
    return this.rootUrl + '/last-movements';
  }

  public resolveOperationalBlock() {
    return '/operational-block/summary';
  }
}
