import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {Kyc} from '../model/kyc/kyc.model';
import {Localstorage} from '../service/storage/localstorage';

export interface KycState {
  kyc: Kyc | null;
}

export const KYC_NAMESPACE = '__kyc__';

const emptyState = () => ({
  kyc: null,
});

@Injectable({
  providedIn: 'root',
})
export default class KycStore extends ComponentStore<KycState> {
  public readonly kyc$ = this.select(state => state.kyc);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveKyc(kyc: Kyc): void {
    this.patchState(() => ({
      kyc
    }));
    this.localStorage.set(KYC_NAMESPACE, JSON.stringify(kyc));
  }

  syncKyc(): Kyc | null {
    return this.get().kyc;
  }

  reset(): void {
    this.setState(() => (emptyState()));
    this.localStorage.unset(KYC_NAMESPACE);
  }
}
