import {Component, Input} from '@angular/core';
import {WebComponent} from 'src/app/ui/webapp/component/web.component';
import LoaderStatusStore from 'src/app/infrastructure/store/loaderStatusStore';

export type BgColor = 'light' | 'dark';
export type BubbleColor = 'primary';

@Component({
  selector: 'app-loader-overplay',
  templateUrl: 'loader-overplay.component.html',
})
export class LoaderOverplayComponent extends WebComponent {
  @Input() color: BubbleColor = 'primary';
  @Input() bgColor: BgColor = 'light';

  loading = false;

  constructor(
    private readonly loaderStatusStore: LoaderStatusStore
  ) {
    super();
    this.subscribe(this.loaderStatusStore.showed$, (show) => this.loading = show);
  }
}
