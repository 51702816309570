import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import RouteResolverService from 'src/app/domain/service/route-resolver/route-resolver.service';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {Router} from 'src/app/infrastructure/router/router';
import {Modal} from 'src/app/infrastructure/service/modal.service';
import {WebPage} from 'src/app/ui/webapp/page/web.page';

@Component({
  selector: 'app-internal-server-error',
  templateUrl: 'internal-server-error.page.html',
})
export class InternalServerErrorPage extends WebPage {
  constructor(
    private routeResolver: RouteResolverService,
    private modal: Modal,
    router: Router,
    appEnvironment: AppEnvironmentService,
    titleService: Title
  ) {
    super(router, appEnvironment, titleService);
  }

  onInit(): void {
    this.modal.dismiss();
  }

  refresh(): void {
    this.navigate(this.routeResolver.resolve());
  }
}
