export const scrollTo = (el: HTMLElement): void => {
  el.scrollIntoView({
    behavior: 'smooth'
  });
};

export const scrollToTop = (): void => {
  const scroll: Element = scrollRootElement();
  scroll && scroll.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const scrollRootElement = (): Element => document.body.querySelector('#main-layout');
