import {Injectable} from '@angular/core';
import PlanStore from '../../store/plan.store';
import {filter, Observable, tap} from 'rxjs';
import {ApiClient} from '../../../infrastructure/http/api.client';
import {Plan} from '../../model/plan/plan.model';
import {objKeysToSnakeCase} from '../../function/object.helper';
import {UpdatePlan} from '../../command/plan/plan.command';
import {ContractPlanCommand} from '../../command/plan/ContractPlanCommand';

@Injectable({
  providedIn: 'root',
})
export default class PlanApi {

  private readonly url = 'lead/plan';
  private readonly contractUrl = 'plan';
  private fetching: boolean;

  constructor(private api: ApiClient, private planStore: PlanStore) {
    this.fetching = false;
  }

  syncPlan(): Plan {
    return this.planStore.syncPlan();
  }

  public plan(): Observable<Plan> {
    return this.planStore.plan$.pipe(
      tap(stored => {
        if (!stored && !this.fetching) {
          this.fetch();
        }
      }),
      filter(stored => !!stored)
    );
  }

  public async fetch(): Promise<void> {
    this.fetching = true;
    const plan: Plan = await this.api.get(this.url);
    this.planStore.savePlan(plan);
    this.fetching = false;
  }

  public async update(command: UpdatePlan): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.post(this.url, snakeCommand);
    await this.fetch();
  }

  public async contract(command: ContractPlanCommand): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.post(this.contractUrl, snakeCommand);
    await this.fetch();
  }

  public async waitFetchAndSync(): Promise<Plan> {
    let plan = this.syncPlan();
    if (!plan) {
      await this.fetch();
      plan = this.syncPlan();
    }
    return plan;
  }
}
