import {Injectable} from '@angular/core';
import {isNil} from 'lodash';
import BiometricStatusStore, {BiometricStatusState} from 'src/app/infrastructure/store/biometric-status.store';
import AuthStore from '../../domain/store/auth.store';
import UserStore from '../../domain/store/user.store';
import {User} from '../../domain/model/user.model';
import UserApi from '../../domain/service/api/user.api';
import {Auth} from '../../domain/model/auth.model';

@Injectable({
  providedIn: 'root',
})

export class UserSession {

  constructor(
    private authStore: AuthStore,
    private userStore: UserStore,
    private userApi: UserApi,
    private biometricStore: BiometricStatusStore,
  ) {
  }

  public loggedUser(): User | null {
    return this.syncUser();
  }

  public authInfo(): Auth {
    return this.authStore.syncMe();
  }

  public isLogged(): boolean {
    return this.authStore.syncMe() !== null;
  }

  public deactivateBiometricLogin(activateReminder?: boolean): void {
    const data = this.biometricStore.sync();
    const dismissActivationReminder = isNil(activateReminder) ? data.dismissActivationReminder : !activateReminder;

    this.biometricStore.save(null, false, dismissActivationReminder);
  }

  public activateBiometricLogin(): void {
    const data = this.biometricStore.sync();
    this.biometricStore.save(this.userApi.syncMe().id, true, data.dismissActivationReminder);
  }

  public rejectBiometricDataActivationReminder(): void {
    const data = this.biometricStore.sync();
    const user = this.syncUser();

    if (!isNil(data.userId) && !this.isSameBiometricUser(data, user)) {
      return;
    }

    this.biometricStore.save(user.id, data.enabled, true);
  }

  public userHasRejectedBiometricActivationReminder(): boolean {
    return this.biometricStore.sync().dismissActivationReminder;
  }

  public biometricLoginIsEnabledForAnyUser(): boolean {
    return this.biometricStore.sync().enabled;
  }

  public biometricLoginIsEnabled(): boolean {
    const biometricData = this.biometricStore.sync();
    return biometricData.enabled
           && (!this.isLogged() || this.isSameBiometricUser(biometricData, this.syncUser()));
  }

  private isSameBiometricUser(data: BiometricStatusState, actualUser: User | null): boolean {
    return (!isNil(actualUser) && actualUser.id === data.userId);
  }

  private syncUser(): User | null {
    return this.userApi.syncMe();
  }
}
