import {Command} from 'src/app/domain/command/command.command';
import {uuid} from 'src/app/domain/function/uuid.helper';
import {Platform} from 'src/app/domain/model/app/app-version.model';

export interface AppVersionRequestCommand extends Command {
  version: string;
  platform: Platform;
}

export const createAppVersionRequestCommand = (
  version: string,
  platform: Platform,
): AppVersionRequestCommand => ({
  commandId: uuid(),
  version,
  platform
});
