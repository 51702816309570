import {Pipe, PipeTransform} from '@angular/core';
import {first} from 'rxjs/operators';
import {addParams, Translatable} from 'src/app/domain/service/translator/translatable';
import {Translator} from 'src/app/infrastructure/translation/translator.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslationPipe implements PipeTransform {

  private msg = '';

  constructor(private translator: Translator) {

  }

  transform(translatable: Translatable, ...args: any[]): string {
    translatable = addParams(translatable, args[0]);
    this.translator.asyncTrans(translatable)
      .pipe(first())
      .subscribe((msg) => this.msg = msg);

    return this.msg;
  }
}
