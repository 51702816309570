import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {PortfolioRecommendation} from '../model/portfolio/portfolio-recommendation.model';
import {Observable} from 'rxjs';

export interface CustomerPortfolioState {
  recommendations: PortfolioRecommendation[];
}

const emptyState = () => ({
  recommendations: []
});

@Injectable({
  providedIn: 'root',
})
export default class PortfolioRecommendationStore extends ComponentStore<CustomerPortfolioState> {
  public readonly recommendations$ = this.select(state => state.recommendations);

  constructor() {
    super(emptyState());
  }

  saveAll(recommendations: PortfolioRecommendation[]): void {
    this.patchState(() => ({
      recommendations
    }));
  }

  all(): Observable<PortfolioRecommendation[]> {
    return this.recommendations$;
  }

  syncAll(): PortfolioRecommendation[] | null {
    return this.get().recommendations;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
  }
}
