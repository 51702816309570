import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {Localstorage} from '../service/storage/localstorage';
import {PortfolioSnapshot} from '../model/portfolio-snapshot.model';

export interface PortfolioSnapshotState {
  portfolioId: string;
  snapshots: PortfolioSnapshot[];
  selected: PortfolioSnapshot | null;
}

export const SELECTED_PORTFOLIO_SNAPSHOT_NAMESPACE = '__selected_portfolio_snapshot__';

const emptyState = () => ({
  portfolioId: '',
  snapshots: [],
  selected: null
});

@Injectable({
  providedIn: 'root',
})
export default class PortfolioSnapshotStore extends ComponentStore<PortfolioSnapshotState> {
  public readonly snapshots$ = this.select(state => state.snapshots);
  public readonly portfolioIdOfSnapshots$ = this.select(state => state.portfolioId);
  public readonly selected$ = this.select(state => state.selected);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveAll(portfolioId: string, snapshots: PortfolioSnapshot[]): void {
    this.patchState(() => ({
      portfolioId,
      snapshots
    }));
  }

  saveSelected(selected: PortfolioSnapshot): void {
    this.patchState(() => ({
      selected
    }));
    this.localStorage.syncSet(SELECTED_PORTFOLIO_SNAPSHOT_NAMESPACE, JSON.stringify(selected));
  }

  syncSelected(): PortfolioSnapshot | null {
    return this.get().selected;
  }

  syncPortfolioIdOfSnapshots(): string {
    return this.get().portfolioId;
  }

  syncAll(): PortfolioSnapshot[] | null {
    return this.get().snapshots;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
    this.localStorage.unset(SELECTED_PORTFOLIO_SNAPSHOT_NAMESPACE);
  }
}
