import { Injectable } from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerIoService {
  constructor() {}

  addCustomerIoScript(siteId: string, userId: string, data: object) {
    if (!this.shouldIncludeCustomerIoScript()) {
      return;
    }

    /* eslint-disable */
    if ((window as any)._cio && Array.isArray((window as any)._cio)) {
      return;
    }

    // @ts-ignore
    const _cio = [] || _cio;
    (window as any)._cio = _cio;

    let c: number;


    const a: (f: string) => (...args: any[]) => void = function(f) {
      return function(...args: any[]) {
        _cio.push([f].concat(args));
      };
    };

    const b: string[] = ['load', 'identify', 'sidentify', 'track', 'page', 'on', 'off'];
    for (c = 0; c < b.length; c++) {
      _cio[b[c]] = a(b[c]);
    }
    /* eslint-enable */

    const t = document.createElement('script');
      const s = document.getElementsByTagName('script')[0];
    t.async = true;
    t.id = 'cio-tracker';
    t.setAttribute('data-site-id', siteId);
    t.setAttribute('data-use-array-params', 'true');
    t.setAttribute('data-use-in-app', 'true');
    t.src = 'https://assets.customer.io/assets/track.js';
    s.parentNode.insertBefore(t, s);

    _cio.identify({
      id: userId,
      data
    });
  }

  private shouldIncludeCustomerIoScript(): boolean {
    return environment.analytics.customerIoSiteId !== null;
  }
}
