import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {CustomerPortfolio} from '../model/customer-portfolio.model';
import {Localstorage} from '../service/storage/localstorage';

export interface CustomerPortfolioState {
  portfolios: CustomerPortfolio[];
  selected: CustomerPortfolio | null;
}

export const SELECTED_PORTFOLIO_NAMESPACE = '__selected_portfolio__';

const emptyState = () => ({
  portfolios: [],
  selected: null
});

@Injectable({
  providedIn: 'root',
})
export default class CustomerPortfolioStore extends ComponentStore<CustomerPortfolioState> {
  public readonly portfolios$ = this.select(state => state.portfolios);
  public readonly selected$ = this.select(state => state.selected);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveAll(portfolios: CustomerPortfolio[]): void {
    this.patchState(() => ({
      portfolios
    }));
  }

  saveSelected(selected: CustomerPortfolio): void {
    this.patchState(() => ({
      selected
    }));
    this.localStorage.syncSet(SELECTED_PORTFOLIO_NAMESPACE, JSON.stringify(selected));
  }

  syncSelected(): CustomerPortfolio | null {
    return this.get().selected;
  }

  syncAll(): CustomerPortfolio[] | null {
    return this.get().portfolios;
  }

  reset(): void {
    this.setState(() => (emptyState()));
    this.localStorage.unset(SELECTED_PORTFOLIO_NAMESPACE);
  }
}
