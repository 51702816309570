export const toFormData = (formValue: any): FormData => {
  const formData = new FormData();

  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    formData.append(key, value);
  }

  return formData;
};

export const isFile = input => 'File' in window && input instanceof File;
export const isBlob = input => 'Blob' in window && input instanceof Blob;
export const isBinary = input => isFile(input) || isBlob(input);
