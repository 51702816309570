import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ChangeEmailCommand, ChangePasswordCommand, CheckPasswordCommand, ConfirmEmailCommand, ConfirmPhone, UpdateIsCompanyManagerCommand, UpdatePublicChargeCommand, UpdateUser} from 'src/app/domain/command/user.command';
import {ERROR_CODES} from 'src/app/domain/error/error-codes';
import ValidationError from 'src/app/domain/error/validation-error';
import {objKeysToSnakeCase} from 'src/app/domain/function/object.helper';
import {sleep} from 'src/app/domain/function/promise.helper';
import {User} from 'src/app/domain/model/user.model';
import UserStore from 'src/app/domain/store/user.store';
import {ApiClient} from 'src/app/infrastructure/http/api.client';
import {HttpResponse, isNoContent} from 'src/app/infrastructure/http/http-response';

@Injectable({
  providedIn: 'root',
})
export default class UserApi {
  private readonly url = 'user';

  constructor(private http: ApiClient, private userStore: UserStore) {
  }

  public async updateAuthUser(command: UpdateUser): Promise<void> {
    await sleep(200 + Math.random() * 200);
  }

  public async fetchMe(): Promise<void> {
    const user: User = await this.http.get('');
    this.userStore.saveAuthUser(user);
  }

  public me(): Observable<User | null> {
    return this.userStore.me$;
  }

  public syncMe(): User | null {
    return this.userStore.syncUser();
  }

  public async checkPhoneNumber(phone: string): Promise<boolean> {
    try {
      await this.http.head(
          this.url + '/phone?phone=' + encodeURIComponent(phone));
      return true;
    } catch (e) {
      return false;
    }
  }

  public async checkEmail(email: string): Promise<void> {
    const response = await this.http.head<HttpResponse>(
        this.url + '/email', {email}, {observe: 'response'});
    if (isNoContent(response)) {
      return;
    }
    throw new ValidationError(ERROR_CODES.invalidCheckEmail, 'shared.errors.email');
  }

  public async waitFetchAndSync(): Promise<User> {
    let user = this.syncMe();
    if (!user) {
      await this.fetchMe();
      user = this.syncMe();
    }
    return user;
  }

  public async updatePhone(command: ConfirmPhone): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.http.patch(this.url + '/phone/confirm',snakeCommand);
    await this.waitFetchAndSync();
  }

  public async confirmPhone(authSignatureSms: string): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    await this.http.patch(this.url + '/phone/confirm',{auth_signature_sms: authSignatureSms});
  }

  public async startEmailAddressVerificationProcess(email: string): Promise<void> {
    await this.http.patch('login/email', {email});
  }

  public async finishEmailAddressVerificationProcess(code: string): Promise<void> {
    await this.http.post('login/email/confirm', {code});
  }

  public async onboard(): Promise<void> {
    await this.http.post(this.url + '/onboard');
    this.fetchMe();
  }

  public async checkPassword(checkPassword: CheckPasswordCommand): Promise<void> {
    const response = await this.http.post(this.url + '/password', objKeysToSnakeCase(checkPassword), {}, {observe: 'response'});
    if (isNoContent(response)) {
      throw new ValidationError(ERROR_CODES.invalidCheckEmail, 'shared.errors.password');
    }
    this.fetchMe();
  }

  public async updatePassword(changePassword: ChangePasswordCommand): Promise<void> {
    const user: User = this.userStore.syncUser();
    await this.http.patch('?id=' + user.id, objKeysToSnakeCase(changePassword));
    this.fetchMe();
  }

  public async updateEmail(changeEmail: ChangeEmailCommand): Promise<void> {
    await this.http.patch(this.url + '/email', objKeysToSnakeCase(changeEmail));
    this.fetchMe();
  }

  public async confirmEmail(confirmEmail: ConfirmEmailCommand): Promise<void> {
    await this.http.patch(this.url + '/email/confirm', objKeysToSnakeCase(confirmEmail));
    await this.fetchMe();
  }

  public async updatePublicCharge(updatePublicChargeCommand: UpdatePublicChargeCommand): Promise<void> {
    await this.http.patch(this.url + '/public-charge', objKeysToSnakeCase(updatePublicChargeCommand));
    this.fetchMe();
  }
  public async updateIsCompanyManager(updateIsCompanyManager: UpdateIsCompanyManagerCommand): Promise<void> {
    await this.http.patch(this.url + '/company-manager', objKeysToSnakeCase(updateIsCompanyManager));
    this.fetchMe();
  }
}
