/* eslint-disable max-len */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {firstValueFrom, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {notNil} from 'src/app/domain/function/function.helper';
import {objectFilter} from 'src/app/domain/function/object.helper';
import {HttpObserve, HTTPOptions, HttpReturnType} from 'src/app/infrastructure/http/http-options';
import {HttpResponse} from 'src/app/infrastructure/http/http-response';
import {FileSaver} from '../service/file-saver.service';
import {ApiVersion, RouteGenerator} from './route-generator';

interface ApiHttpOptions<T extends HttpObserve = HttpObserve, P extends HttpReturnType = HttpReturnType> extends HTTPOptions<T, P>{
  apiVersion?: ApiVersion;
}

@Injectable({
  providedIn: 'root'
})
export class ApiClient {
  constructor(
    private http: HttpClient,
    private routeGenerator: RouteGenerator,
    private fileSaver: FileSaver
  ) {
  }

  public async download(response: HttpResponse<Blob>, attachmentFilename: string): Promise<void> {
    await this.fileSaver.saveDocument(response.body, attachmentFilename);
  }

  public async get<T>(url: string, params?: any, httpOptions?: ApiHttpOptions<'body'>): Promise<T>;
  public async get<T>(url: string, params?: any, httpOptions?: ApiHttpOptions<'response'>): Promise<HttpResponse<T>>;
  public async get<T>(url: string, params?: any, httpOptions?: ApiHttpOptions): Promise<T | HttpResponse<T>> {
    return this.request('GET', url, null, params, httpOptions as any);
  }

  public async post<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions<'body'>): Promise<T>;
  public async post<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions<'response'>): Promise<HttpResponse<T>>;
  public async post<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions): Promise<T | HttpResponse<T>> {
    return this.request('POST', url, body, params, httpOptions as any);
  }

  public async put<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions<'body'>): Promise<T>;
  public async put<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions<'response'>): Promise<HttpResponse<T>>;
  public async put<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions): Promise<T | HttpResponse<T>> {
    return this.request('PUT', url, body, params, httpOptions as any);
  }

  public async patch<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions<'body'>): Promise<T>;
  public async patch<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions<'response'>): Promise<HttpResponse<T>>;
  public async patch<T>(url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions): Promise<T | HttpResponse<T>> {
    return this.request('PATCH', url, body, params, httpOptions as any);
  }

  public async head<T>(url: string, params?: any, httpOptions?: ApiHttpOptions<'body'>): Promise<T>;
  public async head<T>(url: string, params?: any, httpOptions?: ApiHttpOptions<'response'>): Promise<HttpResponse<T>>;
  public async head<T>(url: string, params?: any, httpOptions?: ApiHttpOptions): Promise<T | HttpResponse<T>> {
    return this.request('HEAD', url, null, params, httpOptions as any);
  }

  private async request<T>(method: string, url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions<'body'>): Promise<T>;
  private async request<T>(method: string, url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions<'response'>): Promise<HttpResponse<T>>;
  private async request<T>(method: string, url: string, body?: any, params?: any, httpOptions?: ApiHttpOptions): Promise<T | HttpResponse<T>> {
    httpOptions = {observe: 'body', responseType: 'json', apiVersion: 'v1', ...(httpOptions ?? {})};
    const absoluteUrl = this.routeGenerator.absoluteUrl(url, httpOptions.apiVersion);

    if (params) {
      params = objectFilter(params, (value) => notNil(value));
    }

    return await firstValueFrom(this.http.request<T>(method, absoluteUrl, {...httpOptions as any, body, params}).pipe(
      catchError((error: any) => throwError(() => error))
    )) as T | HttpResponse<T>;
  }
}
