import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FormScrollDirective} from 'src/app/infrastructure/directive/form-scroll.directive';
import {AppFormBuilder} from './form/app-form-builder.service';
import {ApiClient} from './http/api.client';
import {Translator} from './translation/translator.service';

@NgModule({
  imports: [HttpClientModule, ReactiveFormsModule,TranslateModule],
  declarations: [FormScrollDirective],
  exports: [FormScrollDirective],
  providers: [HttpClient, ApiClient, FormBuilder, AppFormBuilder,Translator],
})
export class InfrastructureModule {
}
