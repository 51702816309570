import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {isEqual} from 'lodash';
import {Auth} from '../model/auth.model';
import {Localstorage} from '../service/storage/localstorage';

export interface AuthState {
  me: Auth | null;
}

export const AUTH_NAMESPACE = '__auth__';

const emptyState = () => ({
  me: null,
});

@Injectable({
  providedIn: 'root',
})
export default class AuthStore extends ComponentStore<AuthState> {
  public readonly me$ = this.select(state => state.me);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveMe(me: Auth): void {
    if (isEqual(this.syncMe(), me)) {
      return;
    }

    this.patchState(() => ({
      me
    }));

    this.localStorage.syncSet(AUTH_NAMESPACE, JSON.stringify(me));
  }

  syncMe(): Auth | null {
    return this.get().me;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
    this.localStorage.unset(AUTH_NAMESPACE);
  }
}
