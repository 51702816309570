import {format} from 'date-fns';
import {isNil} from 'lodash';
import {Checked} from 'src/app/domain/command/command.command';
import {UpdateCustomerData} from 'src/app/domain/command/customer/UpdateCustomerData';
import {Child} from 'src/app/domain/model/child/child.model';
import {Activity} from 'src/app/domain/model/customer/activity.model';
import {TaxLiability} from 'src/app/domain/model/lead/tax-liability.model';
import {Address} from 'src/app/domain/model/shared/address.model';
import {Nationality} from 'src/app/domain/model/shared/nationality.model';
import {OperationalBlockReasons} from 'src/app/domain/model/customer/operational-block-reason/operationalBlockReasons.model';

export type Sex = 'male' | 'female';
export type CustomerType = 'legal' | 'physical';

export interface Customer {
  id: string;
  tolerance: number;
  email: string;
  name: string;
  surname?: string;
  noSecondSurname: Checked;
  vat: string;
  vatExpirationDate: string; // "2016-05-28T14:44:31"
  vatReviewedAt: string; // "2016-05-28T14:44:31"
  phone: string;
  premium: boolean;
  profiledAt: string; // "2016-05-28T14:44:31"
  publicCharge: string;
  publicChargeKind?: string;
  sex: Sex;
  username: string;
  onBoardedAt?: string; // "2016-05-28T14:44:31"
  registrationAge: number;
  type: CustomerType;
  birthdate?: string;
  countryOfBirth?: string;
  cityOfBirth?: string;
  nationalities: Nationality[];
  address: Address;
  child: Child;
  numberOfChildren?: number;
  maritalStatus?: string;
  maritalRegime?: string;
  activity?: Activity;
  employmentStatus?: string;
  incomeOrigin?: string;
  incomeVolume?: string;
  taxObligationsInAnotherCountry?: boolean;
  taxLiabilities?: TaxLiability[];
  operationalBlockReasons: OperationalBlockReasons;
  updatedAt?: string; // "2016-05-28T14:44:31"
}

export const updateCustomerFromDataFilled = (customer: Customer, data: UpdateCustomerData): Customer => {
  customer.name = data.name;
  customer.surname = data.surname;
  customer.activity = {...customer.activity, id: data.activity};

  if (data.postalStreetType && data.postalStreet && data.postalStreetNumber) {
    customer.address = {
      postalStreetType: data.postalStreetType,
      postalStreet: data.postalStreet,
      postalStreetNumber: data.postalStreetNumber,
      postalExtraData: data.postalExtraData,
      city: data.city,
      country: data.country,
      state: data.state,
      zipCode: data.zip
    };
  }

  if (data.birthdate) {
    customer.birthdate = format(new Date(data.birthdate), 'yyyy/MM/dd');
  }

  customer.countryOfBirth = data.countryOfBirth;
  customer.cityOfBirth = data.cityOfBirth;

  customer.numberOfChildren = data.numberOfChildren;
  customer.employmentStatus = data.employmentStatus;
  customer.incomeOrigin = data.incomeOrigin;
  customer.incomeVolume = data.incomeVolume;
  customer.maritalStatus = data.maritalStatus;
  customer.maritalRegime = data.maritalRegime;

  if (data.nationality1) {
    customer.nationalities = [];

    if (data.nationality1 !== '') {
      customer.nationalities.push({country: data.nationality1});
    }

    if (data.nationality2 !== '') {
      customer.nationalities.push({country: data.nationality2});
    }

    if (data.nationality3 !== '') {
      customer.nationalities.push({country: data.nationality3});
    }
  }

  customer.publicCharge = data.publicCharge;
  customer.publicChargeKind = data.publicChargeKind !== '' ? data.publicChargeKind : null;
  customer.taxObligationsInAnotherCountry = data.taxObligationsInAnotherCountry;
  customer.taxLiabilities = [];

  if (data.taxLiabilityVat1 !== '' && data.taxLiabilityCountry1 !== '') {
    customer.taxLiabilities.push({country: data.taxLiabilityCountry1, vat: data.taxLiabilityVat1});
  }

  if (data.taxLiabilityVat2 !== '' && data.taxLiabilityCountry2 !== '') {
    customer.taxLiabilities.push({country: data.taxLiabilityCountry2, vat: data.taxLiabilityVat2});
  }

  if (data.taxLiabilityVat3 !== '' && data.taxLiabilityCountry3 !== '') {
    customer.taxLiabilities.push({country: data.taxLiabilityCountry3, vat: data.taxLiabilityVat3});
  }

  return customer;
};

export const customerFullName = (customer: Customer): string => isNil(customer) ? '' : customer.name + (isNil(customer.surname) ? '' :  ' ' + customer.surname);
export const isLegalCustomer = (customer: Customer): boolean => customer?.type === 'legal';
export const isPhysicalCustomer = (customer: Customer): boolean => customer?.type === 'physical';
export const isPremiumCustomer = (customer: Customer): boolean => customer?.premium;
export const customerHasVatReviewed = (customer: Customer): boolean => !isNil(customer.vatReviewedAt);
export const hasPublicCharge = (customer: Customer): boolean => !isNil(customer?.publicCharge) && customer.publicCharge !== 'no_public_charge';

export const hasOperationalBlockReasons = (customer: Customer): boolean => !isNil(customer?.operationalBlockReasons) && customer?.operationalBlockReasons?.value?.length > 0;
