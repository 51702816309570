import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/ui/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.analytics.segmentWriteKey) {
  // eslint-disable-next-line no-underscore-dangle
  (analytics as any)._writeKey = environment.analytics.segmentWriteKey;
  analytics.load(environment.analytics.segmentWriteKey);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
