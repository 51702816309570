<app-page-header class="text-lg"
                 [title]="titleTransKey() | translate"
                 theme="light">

  <div rightLinks *ngIf="showCloseButton()">
    <ion-icon (click)="close()" name="close-outline" class="cursor-pointer text-3xl"></ion-icon>
  </div>
</app-page-header>

<div class="flex flex-col h-full w-[90%] mx-auto">
  <div class="mx-auto w-1/3">
    <img
      src="/assets/finizens/icon/icon.png"
      alt="Finizens"
    />
  </div>
  <div class="text-center text-lg my-6 w-[95%] mx-auto">
    <p>{{ 'support.appUpdate.description1' | translate }}</p>
    <p class="mt-2 text-warning">{{ description2TransKey() | translate}}</p>
  </div>
    <app-filled-button expand="full" size="md" textSize="lg" (click)="confirm()">
      <ion-icon [name]="osLogo()" class="text-xl align-bottom mr-2"></ion-icon>
      {{ 'support.appUpdate.confirmButton' | translate}}
    </app-filled-button>
</div>

<div class="">
  <p class="flex justify-center text-center"></p>
</div>
