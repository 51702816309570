import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {isEqual} from 'lodash';
import {Aspect, BgColor, Button, Style} from 'src/app/infrastructure/style/style';

export interface StyleState {
  style: Style;
}

const emptyState = () => ({
  style: {
    bgColor: 'default' as BgColor,
    buttons: ['all' as Button],
    opacityOn: false,
    aspect: 'normal' as Aspect,
  },
});

@Injectable({
  providedIn: 'root',
})
export default class StyleStore extends ComponentStore<StyleState> {
  public readonly style$ = this.select(state => state.style);

  constructor() {
    super(emptyState());
  }

  save(style: Style): void {
    if (isEqual(this.sync(), style)) {
      return;
    }

    this.patchState(() => ({
      style
    }));
  }

  sync(): Style | null {
    return this.get().style;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
  }
}
