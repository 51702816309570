import {isNil} from 'lodash';
import * as Product from 'src/app/domain/model/product/product.model';
import {CustomerPortfolioAllocation, isCashAllocation} from './customer-portfolio-allocation.model';
import {Child} from './child/child.model';
import {CustomerPortfolioOrder} from './customer-portfolio-order.model';
import {Customer} from './customer/customer.model';

export const LOCKED_REASONS = {
  activeOrder: 'active_order',
  changingPortfolioMaster: 'changing_portfolio_master',
  extraordinaryDeposit: 'extraordinary_deposit',
  extraordinaryReturn: 'extraordinary_return',
  monthlyDeposit: 'monthly_deposit',
  returnOrRenewal: 'return_or_renewal',
  withdrawal: 'withdrawal'
};
export type LockedReason = typeof LOCKED_REASONS[keyof typeof LOCKED_REASONS];

export interface ExternalOnBoarding {
  identityValidatedAt: string; // "2021-11-18T12:45:30"
  videoCallDoneAt: string | null; // "2021-11-18T12:45:30"
  moneySentAt: string | null; // "2021-11-18T12:45:30"
}

export interface CustomerPortfolio {
  id: string;
  enabled: boolean;
  title: string;
  contractedAt: string; // "2021-11-18T12:45:30"
  canceledAt: string | null; // "2021-11-18T12:45:30"
  linkedAt: string | null; // "2021-11-18T12:45:30"
  productId: Product.ProductId;
  performance: number;
  performanceAmount: number;
  internalRateOfReturn: number;
  invalidAmountToInvest: number;
  isOnRebalance: boolean;
  isExpectingIncomingTransfer: boolean;
  monthlyAmount: number;
  monthlyDepositEnabled: boolean;
  monthlyLockedUntil: string | null;
  value: number;
  tolerance: number;
  premium: boolean;
  externalOnBoarding: ExternalOnBoarding;
  allocations: CustomerPortfolioAllocation[];
  portfolioMaster: string;
  portfolioMasterSetAt: string; // "2021-11-18T12:45:30"
  lockedReason: LockedReason | null | undefined;
  lockEndDate: string | null;
  activeOrder: CustomerPortfolioOrder | undefined;
  depositedAmount: number;
  detractedAmount: number;
  withdrawedAmount: number;
  initialAmount: number;
  child: Child;
  managementFeeFreeAmount: number | null;
  nav: number;
  shares: number;
  internalRateOfReturnVersusBenchmark: number;
  performanceVersusBenchmark: number;
  baseManagementFee: number | null;
  loyaltyDiscount: number | null;
  totalManagementFeeApplied: number | null;
  promotionsDiscount: number | null;
}

export const isInvestmentPortfolio = (portfolio: CustomerPortfolio): boolean => portfolio && Product.isInvestmentPlan(portfolio.productId);
export const isPensionPlanPortfolio = (portfolio: CustomerPortfolio): boolean => portfolio && Product.isPensionPlan(portfolio.productId);
export const isUnitLinkedPortfolio = (portfolio: CustomerPortfolio): boolean => portfolio && Product.isUnitLinkedPlan(portfolio.productId);
export const isChildUnitLinkedPortfolio = (portfolio: CustomerPortfolio): boolean => portfolio && Product.isUnitLinkedPlan(portfolio.productId) && !isNil(portfolio.child);
export const isPremiumPortfolio = (portfolio: CustomerPortfolio): boolean => portfolio && portfolio.premium;
export const isEnabledPortfolio = (portfolio: CustomerPortfolio): boolean => portfolio && portfolio.enabled;
export const isPortfolioLinked = (portfolio: CustomerPortfolio): boolean => portfolio && portfolio.linkedAt !== null;
export const isLockedPortfolio = (portfolio: CustomerPortfolio): boolean => portfolioHasActiveOrder(portfolio) || (portfolio.lockedReason !== undefined && portfolio.lockedReason !== null);
export const isCanceledPortfolio = (portfolio: CustomerPortfolio): boolean => !portfolio?.enabled && portfolio?.canceledAt != null;
export const isPortfolioIdentifiedByCustodian = (portfolio: CustomerPortfolio): boolean => portfolio?.externalOnBoarding.identityValidatedAt != null;
export const portfolioHasBiggerToleranceThanCustomer = (portfolio: CustomerPortfolio, customer: Customer): boolean => portfolio.tolerance > customer.tolerance;
export const portfolioHasActiveOrder = (portfolio: CustomerPortfolio): boolean => portfolio?.activeOrder !== undefined && portfolio?.activeOrder !== null;
export const portfolioCanMakeOperations = (portfolio: CustomerPortfolio): boolean => {
  if (isCanceledPortfolio(portfolio) || isLockedPortfolio(portfolio)) {
    return false;
  }

  if (!isInvestmentPortfolio(portfolio) || isPortfolioIdentifiedByCustodian(portfolio)) {
    return true;
  }
};
export const isPortfolioAlreadyInvested = (portfolio: CustomerPortfolio): boolean => {
  if (!isInvestmentPortfolio(portfolio)) {
    return true;
  }

  const investedFunds = portfolio.allocations.filter((allocation => !isCashAllocation(allocation) && !allocation.isBridgeFund));
  return investedFunds.length > 0;
};
export const isPortfolioOnInvalidInvestmentState = (portfolio: CustomerPortfolio): boolean => {
  if (!isInvestmentPortfolio(portfolio)) {
    return false;
  }

  return portfolio.invalidAmountToInvest > 0;
};
export const portfolioCanDoWithdrawal = (portfolio: CustomerPortfolio): boolean => portfolioCanMakeOperations(portfolio) && !isPensionPlanPortfolio(portfolio);
export const portfolioHasMoneyOnTheWay = (portfolio: CustomerPortfolio): boolean => portfolio && portfolio.externalOnBoarding && !isNil(portfolio.externalOnBoarding.moneySentAt);
