export interface HttpResponse<T = any> {
  status: number;
  body?: T;
}

export const isInfo = (response: HttpResponse) => response.status >= 100 && response.status < 200;
export const isSuccess = (response: HttpResponse) => response.status >= 200 && response.status < 300;
export const isRedirection = (response: HttpResponse) => response.status >= 300 && response.status < 400;
export const isClientError = (response: HttpResponse) => response.status >= 400 && response.status < 500;
export const isServerError = (response: HttpResponse) => response.status >= 500 && response.status < 600;
export const isBadRequest = (response: HttpResponse) => 400 === response.status;
export const isNoContent = (response: HttpResponse) => 204 === response.status;
export const isNotInternetConnection = (response: HttpResponse) => 0 === response.status;
export const isUnauthorized = (response: HttpResponse) => 401 === response.status;

