import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

export type ApiVersion = 'v1';

@Injectable({
  providedIn: 'root',
})
export class RouteGenerator {

  static host(): string {
    return environment.apiHost;
  }

  absoluteUrl(relativeUrl: string, apiVersion: ApiVersion): string {
    const apiUrl = RouteGenerator.host();

    return apiUrl + '/' + relativeUrl;
  }


}
