import {Injectable, NgZone} from '@angular/core';
import {isNil} from 'lodash';
import AuthApi from 'src/app/domain/service/api/auth.api';
import {Device} from 'src/app/infrastructure/device/device';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {InactivityTimeoutFormat} from 'src/app/infrastructure/environment/app-environment/InactivityTimeoutFormat';
import {UserSession} from 'src/app/infrastructure/environment/user-session';
import {Router} from 'src/app/infrastructure/router/router';
import InactivityStatusStore from 'src/app/infrastructure/store/inactivity-status.store';

@Injectable({
  providedIn: 'root',
})
export class InactivityHandler {
  private idleTimeoutId: number | null = null;

  constructor(
    private readonly userSession: UserSession,
    private readonly inactivityStore: InactivityStatusStore,
    private readonly device: Device,
    private readonly environment: AppEnvironmentService,
    private readonly authApi: AuthApi,
    private zone: NgZone,
    protected readonly router: Router,
  ) {}

  public init(lastInteraction: Date) {

    if (this.environment.isLoginProviderExternal()) {
      console.log('IH -> I: Prevented by ILPE');
      return;
    }

    this.inactivityStore.save(lastInteraction);
  }

  public notifyAppStateChange(isActiveApp: boolean) {

    if (this.environment.isLoginProviderExternal()) {
      console.log('IH -> Nasc: Prevented by ILPE');
      return;
    }

    if (!isActiveApp) {

      if (this.userSession.isLogged()) {
        this.saveInteractionToStore(new Date());
      }

      return;
    }

    const lastInteraction = this.lastInteraction();

    if (isNil(lastInteraction)) {
      return;
    }

    const milsWithoutInteraction = (new Date()).valueOf() - lastInteraction.valueOf();
    const milsToBecomeToInactiveUser = this.environment.userSessionParameters(InactivityTimeoutFormat.miliseconds).inactivityTimeout;

    if (milsWithoutInteraction < milsToBecomeToInactiveUser) {
      this.createLogoutTimeout(milsToBecomeToInactiveUser - milsWithoutInteraction);
      return;
    }

    this.checkUserAndLogout();
    this.clearTimeout();
  }

  public notifyUserInteraction(): void {

    if (this.environment.isLoginProviderExternal()) {
      console.log('IH -> Nui: Prevented by ILPE');
      return;
    }

    this.clearTimeout();
    this.createLogoutTimeout(this.environment.userSessionParameters(InactivityTimeoutFormat.miliseconds).inactivityTimeout);
  }

  public notifyLogout() {

    if (this.environment.isLoginProviderExternal()) {
      console.log('IH -> Nl: Prevented by ILPE');
      return;
    }

    this.inactivityStore.reset();
  }

  private lastInteraction(): Date | null {
    const last = this.inactivityStore.sync().lastInteraction;
    return last;
  };

  private checkUserAndLogout(): void {
    if (!isNil(this.userSession.loggedUser())) {
      this.authApi.logout();
    }

    this.inactivityStore.reset();
    this.zone.run(() => {
      this.router.navigate(['/']);
    });
  }

  private createLogoutTimeout(miliseconds: number): void {
    this.clearTimeout();

    this.idleTimeoutId = window.setTimeout(() => {
      this.checkUserAndLogout();
      this.clearTimeout();
    }, miliseconds);

    this.saveInteractionToStore(new Date());
  }

  private clearTimeout(): void {
    if (!isNil(this.idleTimeoutId)) {
      window.clearTimeout(this.idleTimeoutId);
      this.idleTimeoutId = null;
    }
  }

  private saveInteractionToStore(date: Date): void {
    this.inactivityStore.save(date);
  }
}
