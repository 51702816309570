import {Injectable, NgZone} from '@angular/core';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {SplashScreen} from '@capacitor/splash-screen';
import {Subscription} from 'rxjs';
import {createAppVersionRequestCommand} from 'src/app/domain/command/app/app-version.command';
import {AuthSignature} from 'src/app/domain/model/auth-signature/auth-signature.model';
import {Auth} from 'src/app/domain/model/auth.model';
import {CustomerPortfolio} from 'src/app/domain/model/customer-portfolio.model';
import {KycStep} from 'src/app/domain/model/kyc-step.model';
import {Kyc} from 'src/app/domain/model/kyc/kyc.model';
import {Lead} from 'src/app/domain/model/lead/lead.model';
import {Onboarding} from 'src/app/domain/model/onboarding.model';
import {LastSelectedPortfolio} from 'src/app/domain/model/portfolio/selected-portfolio.model';
import {User} from 'src/app/domain/model/user.model';
import AppVersionApi from 'src/app/domain/service/api/app-version.api';
import AuthApi from 'src/app/domain/service/api/auth.api';
import LeadApi from 'src/app/domain/service/api/lead.api';
import UserApi from 'src/app/domain/service/api/user.api';
import CustomerAreaRouteResolver from 'src/app/domain/service/route-resolver/customer-area-route-resolver.service';
import {Localstorage} from 'src/app/domain/service/storage/localstorage';
import AuthSignatureStore, {AUTHSIGNATURE_NAMESPACE} from 'src/app/domain/store/auth-signature.store';
import AuthStore, {AUTH_NAMESPACE} from 'src/app/domain/store/auth.store';
import CustomerOperativeBlockedStepsStore, {CustomerOperativeBlockedState, OPERATIVE_BLOCKED_NAMESPACE} from 'src/app/domain/store/customer-operative-blocked-steps.store';
import CustomerPortfolioStore, {SELECTED_PORTFOLIO_NAMESPACE} from 'src/app/domain/store/customer-portfolio.store';
import KycStepStore, {KYC_STEP_NAMESPACE} from 'src/app/domain/store/kyc-step.store';
import KycStore, {KYC_NAMESPACE} from 'src/app/domain/store/kyc.store';
import LeadStore, {LEAD_NAMESPACE} from 'src/app/domain/store/lead.store';
import OnboardingStore, {ONBOARDING_NAMESPACE} from 'src/app/domain/store/onboarding.store';
import LastSelectedPortfolioStore, {LAST_SELECTED_PORTFOLIO_NAMESPACE} from 'src/app/domain/store/portfolio/last-selected-portfolio.store';
import UserStore, {USER_NAMESPACE} from 'src/app/domain/store/user.store';
import {FacebookPixelTracker} from 'src/app/infrastructure/affiliates/facebook-pixel-tracker';
import {TealiumUtagAnalytics} from 'src/app/infrastructure/analytics/tealium-utag-analytics';
import {Device} from 'src/app/infrastructure/device/device';
import {InactivityHandler} from 'src/app/infrastructure/device/inactivity-handler';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {AppVersion} from 'src/app/infrastructure/environment/app-version';
import {AppVersion as AppVersionModel, Platform} from 'src/app/domain/model/app/app-version.model';
import {Router} from 'src/app/infrastructure/router/router';
import {Modal} from 'src/app/infrastructure/service/modal.service';
import BiometricStatusStore, {BIOMETRIC_NAMESPACE, BiometricStatusState} from 'src/app/infrastructure/store/biometric-status.store';
import {INACTIVITY_NAMESPACE, InactivityStatusState} from 'src/app/infrastructure/store/inactivity-status.store';
import LoginStore from 'src/app/infrastructure/store/login.store';
import MenuStatusStore from 'src/app/infrastructure/store/menuStatusStore';
import StyleStore from 'src/app/infrastructure/store/style.store';
import {Translator} from 'src/app/infrastructure/translation/translator.service';
import {AppVersionModalPage} from 'src/app/ui/webapp/page/app-version-modal/modal/app-version.modal';
import {EnvironmentModalPage} from 'src/app/ui/webapp/page/environment-modal/modal/environment.modal';

@Injectable({
  providedIn: 'root'
})
export class Bootstrap {
  public routerEventSubscription: Subscription | undefined;

  constructor(
    private storage: Localstorage,
    private authStore: AuthStore,
    private biometricStore: BiometricStatusStore,
    private userStore: UserStore,
    private leadStore: LeadStore,
    private customerPortfolioStore: CustomerPortfolioStore,
    private operationalBlockStore: CustomerOperativeBlockedStepsStore,
    private lastSelectedPortfolioStore: LastSelectedPortfolioStore,
    private authApi: AuthApi,
    private userApi: UserApi,
    private leadApi: LeadApi,
    private kycStepStore: KycStepStore,
    private kycStore: KycStore,
    private onboardingStore: OnboardingStore,
    private authSignatureStore: AuthSignatureStore,
    private loginStore: LoginStore,
    private inactivityHandler: InactivityHandler,
    private styleStore: StyleStore,
    private translator: Translator,
    private router: Router,
    private modalService: Modal,
    private fbTracker: FacebookPixelTracker,
    private tealium: TealiumUtagAnalytics,
    private cuAreaRouteResolver: CustomerAreaRouteResolver,
    private zone: NgZone,
    private readonly menuStore: MenuStatusStore,
    private environment: AppEnvironmentService,
    private modal: Modal,
    private device: Device,
    private appVersion: AppVersion,
    private appVersionApi: AppVersionApi
  ) {
  }

  bootstrap() {
    console.log('Init bootstrap on ' + this.environment.env());
    this.translator.setLanguage('es');
    this.initBiometric();
    this.initAuth();
    this.initInactivity();
    this.initKycStep();
    this.initKyc();
    this.initOnboarding();
    this.initLead();
    this.initCustomerPortfolios();
    this.initLastSelectedPortfolios();
    this.initAuthSignature();
    this.initStyle();
    this.hideSplash();
    this.controlBackButton();
    this.initDeepLinking();
    this.initializeAffiliateTrackers();
    this.initializeAnalyticsServices();
    this.initTracking();
    this.checkForAppUpdates();
    this.initOperationalBlock();

    if (!this.environment.isProduction() && this.device.isApp()) {
      this.openStagingAlertModal();
    }
  }

  async openStagingAlertModal() {
    await this.modal.present({
        component: EnvironmentModalPage,
        cssClass: 'modal-size-small'
      }
    );
  }

  async appVersionModal(appVersion: AppVersionModel) {
    await this.modal.present({
        component: AppVersionModalPage,
        swipeToClose: false,
        backdropDismiss: false,
        breakpoints: [0.8, 1.0],
        initialBreakpoint: 0.8,
        componentProps: {
          appVersion
        }
      }
    );
  }

  initializeAffiliateTrackers() {
    this.fbTracker.init();
  }

  initializeAnalyticsServices() {
    this.tealium.init();
  }

  hideSplash(): void {
    setTimeout(async () => await SplashScreen.hide({fadeOutDuration: 800}), 700);
  }

  async initTracking() {
    this.routerEventSubscription = this.router.navigationStart().subscribe((route) => {
      analytics.page(route.url);
    });
  }

  async initKyc(): Promise<void> {
    const jsonKyc = await this.storage.get(KYC_NAMESPACE);
    if (!jsonKyc) {
      return;
    }

    const kyc: Kyc = JSON.parse(jsonKyc);
    this.kycStore.saveKyc(kyc);
  }

  async initOnboarding(): Promise<void> {
    const json = await this.storage.get(ONBOARDING_NAMESPACE);
    if (!json) {
      return;
    }

    const onBoarding: Onboarding = JSON.parse(json);
    this.onboardingStore.saveOnboarding(onBoarding);
  }

  async initKycStep(): Promise<void> {
    const jsonKycStep = await this.storage.get(KYC_STEP_NAMESPACE);
    if (!jsonKycStep) {
      return;
    }

    const kycStep: KycStep = JSON.parse(jsonKycStep);
    this.kycStepStore.saveKycStep(kycStep);
  }

  private initLead() {
    const jsonLead = this.storage.syncGet(LEAD_NAMESPACE);

    if (!jsonLead) {
      return;
    }

    const lead: Lead = JSON.parse(jsonLead);
    this.leadStore.saveLead(lead);
  }

  private initCustomerPortfolios() {
    const jsonSelectedPortfolio = this.storage.syncGet(SELECTED_PORTFOLIO_NAMESPACE);

    if (!jsonSelectedPortfolio) {
      return;
    }

    const portfolio: CustomerPortfolio = JSON.parse(jsonSelectedPortfolio);
    this.customerPortfolioStore.saveSelected(portfolio);
  }

  private initOperationalBlock() {
    const jsonOperationalBlock = this.storage.syncGet(OPERATIVE_BLOCKED_NAMESPACE);

    if (!jsonOperationalBlock) {
      return;
    }

    const operationalBlock: CustomerOperativeBlockedState = JSON.parse(jsonOperationalBlock);
    this.operationalBlockStore.saveAll({...operationalBlock, date: new Date(operationalBlock.date)});
  }

  private initLastSelectedPortfolios() {
    const jsonLastSelectedPortfolios = this.storage.syncGet(LAST_SELECTED_PORTFOLIO_NAMESPACE);

    if (!jsonLastSelectedPortfolios) {
      return;
    }

    const lastSelectedPortfolios: LastSelectedPortfolio[] = JSON.parse(jsonLastSelectedPortfolios);
    this.lastSelectedPortfolioStore.saveAll(lastSelectedPortfolios);
  }

  private initAuth() {
    const jsonAuth = this.storage.syncGet(AUTH_NAMESPACE);
    const jsonUser = this.storage.syncGet(USER_NAMESPACE);
    this.loginStore.initData();

    if (!jsonAuth || !jsonUser) {
      return;
    }

    const user: User = JSON.parse(jsonUser);
    this.userStore.saveAuthUser(user);

    const auth: Auth = JSON.parse(jsonAuth);
    this.authStore.saveMe(auth);

    this.userApi.fetchMe();
  }

  private initAuthSignature() {
    const jsonAuthSignature = this.storage.syncGet(AUTHSIGNATURE_NAMESPACE);

    if (!jsonAuthSignature) {
      return;
    }

    const authSignature: AuthSignature = JSON.parse(jsonAuthSignature);
    this.authSignatureStore.saveAuthSignature(authSignature);
  }

  private initBiometric(): void {
    const jsonBiometric = this.storage.syncGet(BIOMETRIC_NAMESPACE);

    if (!jsonBiometric) {
      return;
    }

    const biometric: BiometricStatusState = JSON.parse(jsonBiometric);
    this.biometricStore.save(biometric.userId, biometric.enabled, biometric.dismissActivationReminder);
  }

  private initInactivity() {
    const json = this.storage.syncGet(INACTIVITY_NAMESPACE);

    App.addListener('appStateChange', ({isActive}) => {
      this.inactivityHandler.notifyAppStateChange(isActive);
    });

    if (!json) {
      return;
    }

    const authSignature: InactivityStatusState = JSON.parse(json);

    this.inactivityHandler.init(new Date(authSignature.lastInteraction));
    this.inactivityHandler.notifyAppStateChange(true);
  }

  private initStyle() {
    this.styleStore.reset();
  }

  private controlBackButton() {
    App.addListener('backButton', () => {
      this.inactivityHandler.notifyUserInteraction();

      if (this.menuStore.syncOpened()) {
        this.menuStore.saveOpen(false);
        return;
      }

      if (this.router.currentUrl().url === this.cuAreaRouteResolver.resolveRoot()) {
        App.exitApp();
        return;
      }

      this.modalService.isOpen()
        ? this.modalService.dismiss(undefined)
        : this.router.navigateToBack();
    });
  }

  private async checkForAppUpdates(): Promise<void> {

    if (!this.device.isApp()) {
      return;
    }

    const platform: Platform = this.device.isIosApp() ? 'ios' : 'android';

    await this.showUpdateModal(platform);
  }

  private async showUpdateModal(platform: Platform): Promise<void> {
    await this.device.appVersion().then(async (version) => {
      const command = createAppVersionRequestCommand(version, platform);
      let appVersionRemote = null;
      await this.appVersionApi.waitFetchAndSync(command).then((appVersion: AppVersionModel) => {
        appVersionRemote = appVersion;
      });
      if (appVersionRemote?.updateAction !== 'no_action') {
        await this.appVersionModal(appVersionRemote);
      }
    });
  }

  private initDeepLinking() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        let slug = event.url.split('app.finizens.com').pop();
        slug = slug.replace('/#/', '/');
        slug = slug.replace('/_/', '/');

        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
