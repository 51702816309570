import {AsyncPipe, CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeES from '@angular/common/locales/es';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {SmsRetriever} from '@awesome-cordova-plugins/sms-retriever/ngx';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RollbarErrorHandler, rollbarFactory, RollbarService} from 'src/app/infrastructure/service/error-handler/RollbarErrorHandler.service';
import {InternalServerErrorPage} from 'src/app/ui/webapp/page/error/internal-server/internal-server-error.page';
import {NotFoundErrorPage} from 'src/app/ui/webapp/page/error/not-found/not-found-error.page';
import {DomainModule} from '../domain/domain.module';
import {CamelcaseInterceptor} from '../infrastructure/http/camelcase-interceptor';
import {HttpErrorInterceptor} from '../infrastructure/http/http-error-interceptor';
import {InfrastructureModule} from '../infrastructure/infrastructure.module';
import {AppComponent} from './app.component';
import {AppRouting} from './app.routing';
import {SharedComponentModule} from './shared/component/shared-component.module';
import {WebappLayoutPage} from './webapp/webapp-layout.page';

registerLocaleData(localeES, 'es');

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json');

const getIonicConfig = () => ({
  hardwareBackButton: false
});

@NgModule({
  declarations: [
    AppComponent,
    WebappLayoutPage,
    NotFoundErrorPage,
    InternalServerErrorPage
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(getIonicConfig()),
    AppRouting,
    RouterModule,
    CommonModule,
    InfrastructureModule,
    DomainModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SharedComponentModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CamelcaseInterceptor, multi: true},
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory },
    SmsRetriever,
    AsyncPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
