import {Injectable} from '@angular/core';
import {App} from '@capacitor/app';
import version from 'versions.json';

type Version = { version: string; build: string };

@Injectable({providedIn: 'root'})
export class AppVersion {

  public hash(): string {
    return version.hash;
  }

  public async android(): Promise<string> {
    let appVersion = null;
    await App.getInfo().then((info) => {
      appVersion = info.version;
    });
    return appVersion;
  }

  public web(): Version {
    return version.platforms.web;
  }

  public async ios(): Promise<string> {
    let appVersion = null;
    await App.getInfo().then((info) => {
      appVersion = info.version;
    });
    return appVersion;
  }
}
