import {Injectable} from '@angular/core';
import {Router as NgRouter} from '@angular/router';
import {isNil} from 'lodash';
import {Affiliate} from 'src/app/infrastructure/environment/app-environment/Affiliate';
import {AffiliateEnvironmentConfiguration} from 'src/app/infrastructure/environment/app-environment/AffiliateEnvironmentConfiguration';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';

interface FbPixelAffiliateEnvironmentConfiguration extends AffiliateEnvironmentConfiguration {
  data: { id: string } | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelTracker {
  private configuration: FbPixelAffiliateEnvironmentConfiguration;

  constructor(
    private readonly router: NgRouter,
    private readonly appEnvironment: AppEnvironmentService
  ) {
    this.configuration = this.appEnvironment.affiliateConfiguration(Affiliate.facebookPixel) as FbPixelAffiliateEnvironmentConfiguration;
  }

  public init(): void {
    if (this.isEnabled()) {
      this.addPixelToDOM(this.configuration.data.id);
    }
  }

  private isEnabled(): boolean {
    return  this.configuration.enable && !isNil(this.id()) ;
  }

  private id(): string | null {
    return this.configuration.data.id;
  }

  private addPixelToDOM(pixelId: string): void {
    const js = document.createElement('script');
    const pixel = document.createElement('noscript');
    js.innerHTML = '!function(f,b,e,v,n,t,s)\n' +
      '      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
      '        n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
      '        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';\n' +
      '        n.queue=[];t=b.createElement(e);t.async=!0;\n' +
      '        t.src=v;s=b.getElementsByTagName(e)[0];\n' +
      '        s.parentNode.insertBefore(t,s)}(window, document,\'script\',\n' +
      '        \'https://connect.facebook.net/en_US/fbevents.js\');\n' +
      '      fbq(\'init\', \''+pixelId+'\');\n' +
      '      fbq(\'track\', \'PageView\');';
    pixel.innerHTML = '<img height="1" width="1" style="display:none" src="'+ this.route() + '"/>';
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(js);
    head.appendChild(pixel);
  }

  private route(): string {
    return 'https://www.facebook.com/tr?id=' + this.id() + '&ev=PageView&noscript=1';
  }
}
