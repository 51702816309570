import {Injectable} from '@angular/core';
import {
  AnalyticsService,
  AnalyticsServiceConfiguration
} from 'src/app/infrastructure/environment/app-environment/analytics-service-configuration';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';

type ScriptPlace = 'body' | 'head';

@Injectable({
  providedIn: 'root',
})
export class TealiumUtagAnalytics {
  private readonly configuration: AnalyticsServiceConfiguration;

  constructor(
    private readonly appEnvironment: AppEnvironmentService,
  ) {
    this.configuration = this.appEnvironment.analyticsServiceConfiguration(AnalyticsService.tealium);
  }
  public init(): void {

    if (this.configuration.enabled) {
      // @ts-ignore
      this.addScript('head', this.configuration.data.headScript);
      // @ts-ignore
      this.addScript('body', this.configuration.data.bodyScript);
    }

  }

  private addScript(place: ScriptPlace, route: string): void {
    const pixel = document.createElement('script');
    pixel.setAttribute('src', route);

    document.getElementsByTagName(place)[0].prepend(pixel);
  }
}
