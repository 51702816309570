import {Allocation} from 'src/app/domain/model/allocation.model';

export const ALLOCATION_ISIN_CASH = 'CASH';

export interface CustomerPortfolioAllocation extends Allocation {
  isin: string;
  isBridgeFund: boolean;
  expectedPercent: number;
  amount: number;
  performance: number;
  performanceAmount: number;
  diffPercent: number;
  shares: number;
  nav: number;
}

export const isCashAllocation = (allocation: CustomerPortfolioAllocation): boolean => allocation.isin ===
  ALLOCATION_ISIN_CASH;
