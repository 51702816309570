import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';

export interface LoaderStatus {
  show: boolean;
}

const emptyState = () => ({
  show: false,
});

@Injectable({
  providedIn: 'root',
})
export default class LoaderStatusStore extends ComponentStore<LoaderStatus> {
  public readonly showed$ = this.select(state => state.show);

  constructor() {
    super(emptyState());
  }

  save(show: boolean): void {
    this.patchState(() => ({
      show
    }));
  }

  syncShowed(): boolean {
    return this.get().show;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
  }
}
