import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {LastSelectedPortfolio} from 'src/app/domain/model/portfolio/selected-portfolio.model';
import {Localstorage} from 'src/app/domain/service/storage/localstorage';

export interface LastSelectedPortfolioState {
  lastSelectedPortfolios: LastSelectedPortfolio[];
}

export const LAST_SELECTED_PORTFOLIO_NAMESPACE = '__last_selected_portfolios__';

const emptyState = () => ({
  lastSelectedPortfolios: []
});

@Injectable({
  providedIn: 'root',
})

export default class LastSelectedPortfolioStore extends ComponentStore<LastSelectedPortfolioState> {
  public readonly lastSelectedPortfolios$ = this.select(state => state.lastSelectedPortfolios);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  public saveAll(lastSelectedPortfolios: LastSelectedPortfolio[]): void {
    this.patchState(() => ({
      lastSelectedPortfolios
    }));

    this.localStorage.syncSet(LAST_SELECTED_PORTFOLIO_NAMESPACE, JSON.stringify(lastSelectedPortfolios));
  }

  public save(lastSelectedPortfolio: LastSelectedPortfolio): void {
    const lastSelectedPortfolios: LastSelectedPortfolio[] = this.get().lastSelectedPortfolios;
    const item = lastSelectedPortfolios.find((lastSelectedPortfolioItem) => lastSelectedPortfolioItem.userId === lastSelectedPortfolio.userId);

    if (item) {
      item.portfolioId = lastSelectedPortfolio.portfolioId;
    } else {
      lastSelectedPortfolios.push(lastSelectedPortfolio);
    }

    this.patchState(() => ({
      lastSelectedPortfolios
    }));

    this.localStorage.syncSet(LAST_SELECTED_PORTFOLIO_NAMESPACE, JSON.stringify(lastSelectedPortfolios));
  }

  public syncLastSelectedPortfolios(): LastSelectedPortfolio[] {
    return this.get().lastSelectedPortfolios;
  }

  public syncByPortfolioId(portfolioId: string): LastSelectedPortfolio | null {
    return this.get().lastSelectedPortfolios.find((lastSelectedPortfolio) => lastSelectedPortfolio.portfolioId === portfolioId);
  }

  public reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));

    this.localStorage.unset(LAST_SELECTED_PORTFOLIO_NAMESPACE);
  }
}
