import {Injectable} from '@angular/core';
import {CreateLeadCommand, UpdateLead} from 'src/app/domain/command/lead/lead.command';
import {SetProfiledAt} from 'src/app/domain/command/onboarding.command';
import BackofficePortfolioApi from 'src/app/domain/service/api/backoffice-portfolio.api';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {ProductId} from 'src/app/domain/model/product/product.model';

@Injectable({
  providedIn: 'root',
})
export default class PartnerCommandHidratorService {
  constructor(
    private boPortfolioApi: BackofficePortfolioApi,
    private appEnvironmentService: AppEnvironmentService,
  ) {}

  public hidrateCreateLeadCommand(command: CreateLeadCommand): CreateLeadCommand {
    const product = AppEnvironmentService.products().unitLinked.productId;
    command = {
      ...command,
      planSettings: {
        product,
        productSettings: {
          id: product
        },
      }
    };
    return command;
  }

  public hidrateUpdateLeadCommand(command: UpdateLead): UpdateLead {
    const product = AppEnvironmentService.products().unitLinked.productId as ProductId;
    const planSettings = command.planSettings;
    planSettings.product = product;
    planSettings.productSettings = {
      id: product
    };

    command = {
      ...command,
      planSettings
    };
    return command;
  }

  public hidrateSetProfiledAt(command: SetProfiledAt): SetProfiledAt {

    if (AppEnvironmentService.hasOnlyOneProductConfigured()) {
      command = {
        ...command,
        productSelectedAt: 'now',
      };
    }

    if (AppEnvironmentService.doPartnerProvidesUserData()) {
      command = {
        ...command,
        phoneConfirmedAt: 'now',
        emailConfirmedAt: 'now',
        dataFilledAt: 'now',
        vatUploadedAt: 'now',
      };
    }

    return command;
  }
}
