import {Injectable} from '@angular/core';
import {camelCase, isNil} from 'lodash';
import {Platform} from 'src/app/domain/model/app/app-version.model';
import {PRODUCT_TYPES, ProductId, ProductType} from 'src/app/domain/model/product/product.model';
import {userIsOnboarded} from 'src/app/domain/model/user.model';
import {
  AnalyticsService,
  AnalyticsServiceConfiguration
} from 'src/app/infrastructure/environment/app-environment/analytics-service-configuration';
import {ShareScreenConfiguration} from 'src/app/infrastructure/environment/app-environment/ShareScreenConfiguration';
import {FooterConfiguration} from 'src/app/infrastructure/environment/app-environment/footer-configuration';
import {ProductConfiguration} from 'src/app/infrastructure/environment/app-environment/ProductConfiguration';
import {UserSessionParameters} from 'src/app/infrastructure/environment/app-environment/user-session-parameters';
import {UserSession} from 'src/app/infrastructure/environment/user-session';
import {environment} from 'src/environments/environment';
import {Affiliate} from './Affiliate';
import {AffiliateEnvironmentConfiguration} from './AffiliateEnvironmentConfiguration';
import {ContactPhoneInfo} from './ContactPhoneInfo';
import {CustodianProductEnvironmentConfiguration} from './CustodianProductEnvironmentConfiguration';
import {Fees} from './Fees';
import {InactivityTimeoutFormat} from './InactivityTimeoutFormat';
import {Partner} from './Partner';
import {ProductAmountEnvironmentConfiguration} from './ProductAmountEnvironmentConfiguration';
import {PhonePrefix} from 'src/app/domain/model/country/phone-prefix.model';

@Injectable({
  providedIn: 'root',
})
export class AppEnvironmentService {
  private readonly monthsInAYear = 12;

  constructor(
    private userSession: UserSession
  ) {
  }

  public static welcomeUrl() {
    return environment.welcomeUrl;
  }

  public static products(): { [index: string]: ProductConfiguration } {
    const jsonProducts = environment.products;
    const resultingProducts = {};
    Object.keys(jsonProducts).forEach(
      (productKey) => {
        if (!isNil(jsonProducts[productKey])) {
          resultingProducts[productKey] = jsonProducts[productKey];
        }
      }
    );
    return resultingProducts;
  }

  public static hasOnlyOneProductConfigured(): boolean {
    return Object.entries(AppEnvironmentService.products()).length === 1;
  }

  public static doPartnerProvidesUserData(): boolean {
    return environment.partner.partnerProvidesUserData;
  }

  public static isExternalPartner(): boolean {
    return environment.partner.isExternal;
  }

  public offersProductType(type: ProductType) {
    return !isNil(AppEnvironmentService.products()[camelCase(type)]);
  }

  public isInformedSale(): boolean {
    return environment.partner.informedSale;
  }

  public isEvoPartner(): boolean {
    return (environment.partner.name === Partner.evo);
  }

  public isCaserPartner(): boolean {
    return (environment.partner.name === Partner.caser);
  }

  public shouldShowHereWeExplainItInDetail(): boolean
  {
    return environment.layout.showHereWeExplainItInDetail;
  }

  public shouldShowLogout(): boolean {
    return environment.layout.showLogout;
  }

  public shouldShowFeesMenuButton(productType: ProductType): boolean {
    return environment.products[camelCase(productType)].showFeesMenuButton;
  }

  env(): string {
    return environment.env;
  }

  hostName(): string {
    return environment.hostName;
  }

  rollbar(): object {
    return environment.rollbar;
  }

  fees(): Fees {
    return environment.fees;
  }

  public langs() {
    return environment.langs;
  }

  public debug(): boolean {
    return environment.production === false;
  }

  public isProduction(): boolean {
    return environment.production ;
  }

  public partner(): object {
    return environment.partner;
  }

  public partnerId(): string {
    return environment.partner.id;
  }

  public partnerName(): string {
    return environment.partner.name;
  }

  public apiHost(): string {
    return environment.apiHost;
  }

  public appName(): string {
    return environment.partner.appName;
  }

  public userSessionParameters(inactivityTimeoutFormat: InactivityTimeoutFormat = InactivityTimeoutFormat.minutes): UserSessionParameters {
    return {
      closeSessionOnEveryPageReload: environment.userSession.closeSessionOnEveryPageReload,
      inactivityTimeout: this.inactivityTimeout(inactivityTimeoutFormat),
    };
  }

  public faqUrl(): string | null {
    return environment.partner.faqs.url;
  }

  public privacyPolicyUrl(): string | null {
    return environment.legal.privacyPolicyUrl;
  }

  public legalWarningUrl(): string | null {
    return environment.legal.legalWarningUrl;
  }

  public webUrl(): string | null {
    return environment.partner.webUrl;
  }

  public isAppForThirdPartners(): boolean {
    return environment.partner.name !== 'Finizens';
  }

  public hasContactPage(): boolean {
    return !this.isAppForThirdPartners();
  }

  public investmentPlansUrl(): string {
    return environment.products.investment.productUrl;
  }

  public canManageInvestmentPlans(): boolean {
    return environment.products.investment.isManagedInThisEnvironment;
  }

  public canManagePensionPlans(): boolean {
    return environment.products.pensionPlan.isManagedInThisEnvironment;
  }

  public canManageUnitLinkedPlans(): boolean {
    return environment.products.unitLinked.isManagedInThisEnvironment;
  }

  public isLoginProviderInternal(): boolean {
    return environment.partner.login.provider === 'internal';
  }

  public isLoginProviderExternal(): boolean {
    return environment.partner.login.provider === 'external';
  }

  public loginProvider(): string {
    return environment.partner.login.provider;
  }

  public loginType(): string {
    return environment.partner.login.type;
  }

  public partnerProvidesIbanData(){
    return environment.partner.providesIbanData;
  }

  public hasFixturesEnabled(): boolean {
    return environment.fixtures.enabled;
  }

  public externalLoginFixturePort(): number | null {

    if (isNil(environment.fixtures.externalLogin.port)) {
      return null;
    }

    return environment.fixtures.externalLogin.port;
  }

  public contactPhoneInfo(): ContactPhoneInfo {
    return environment.partner.contact.phone;
  }

  public footerConfiguration(): FooterConfiguration
  {
    return environment.layout.footer as FooterConfiguration;
  }

  public showShareScreen(): boolean {
    return environment.shareScreen.enable;
  }

  public showPoweredBy(): boolean {
    return environment.layout.showPoweredBy;
  }

  public showPotentialAmountKycQuestions(): boolean {
    return environment.partner.kycPotentialAmountQuestions;
  }

  public canSuggestOptimizationRecommendations(): boolean {
    return environment.partner.canFinizensSuggestOptimizationRecommendations;
  }

  public contactEmail(): string | null {
    return environment.partner.contact.email;
  }

  public investmentPlanMinAmountToBeginToInvest(): number {
    return environment.partner.investmentPlan.minimumAmountToBeginToInvest;
  }

  public investmentPlanAmountToBePremium(): number {
    return environment.partner.investmentPlan.amountToBePremium;
  }

  public investmentPlanMinAmountForNextRebalances(): number {
    return environment.partner.investmentPlan.minimumAmountForNextRebalances;
  }

  public productName(productType: ProductType): string {
    const configuration = this.getProductConfiguration(productType);

    return configuration.name;
  }

  public custodianName(productType: ProductType): string {
    const configuration = this.getProductConfiguration(productType);

    return configuration.custodians.name;
  }

  public problematicCifs(productType: ProductType): Array<string>
  {
    const configuration = this.getProductConfiguration(productType);

    return Object.values(configuration.investmentTransfers.problematicCifs);
  }

  public investmentPlanFreeAmountPerCustomerAdded(): number {
    return environment.products.investment.friendPlan.freeAmountPerCustomerAdded;
  }

  public getProductCustodianConfiguration(productType: ProductType, productId: ProductId): CustodianProductEnvironmentConfiguration | null {

    if (
        (productType === PRODUCT_TYPES.unitLinked && !this.canManageUnitLinkedPlans())
        || (productType === PRODUCT_TYPES.pensionPlan && !this.canManagePensionPlans())
        || productType === PRODUCT_TYPES.investment
    ) {
      return null;
    }

    const configuration = this.getProductConfiguration(productType);

    return this.buildProductCustodianConfiguration(
      (configuration.custodians[productId] !== undefined)
        ? configuration.custodians[productId]
        : configuration.custodians.default
    );
  }

  public getProductAmountConfiguration(productType: ProductType, productId: ProductId): ProductAmountEnvironmentConfiguration | null {
    const configuration = this.getProductConfiguration(productType);

    return (configuration.amount[productId] !== undefined) ? configuration.amount[productId] : configuration.amount.default;
  }

  public analyticsServiceConfiguration(service: AnalyticsService): AnalyticsServiceConfiguration {
    const configuration = environment.analytics[camelCase(service)];

    if (isNil(configuration)) {
      throw new Error('Analytics service configuration for service'+ service +' does not exists');
    }

    return configuration;
  }

  public affiliateConfiguration(affiliateName: Affiliate): AffiliateEnvironmentConfiguration {
    const configuration = environment.affiliates[camelCase(affiliateName)];

    if (isNil(configuration)) {
      throw new Error('Affiliate name does not exists');
    }

    return configuration;
  }

  public shareScreenConfiguration(): ShareScreenConfiguration {
    return environment.shareScreen;
  }

  public appStoreLink(platform: Platform): string {
    const link = environment.appStore.appPageLink[camelCase(platform)];

    if (isNil(link)) {
      throw new Error('AppStore link for platform' + platform + 'has not found');
    }

    return link;
  }

  public allowedPhonePrefixes(): PhonePrefix[] {
    return environment.allowedPhonePrefixes;
  }

  private inactivityTimeout(format: InactivityTimeoutFormat = InactivityTimeoutFormat.minutes): number {
    let configValue = environment.userSession.inactivityTimeout.default;

    if (this.userSession.loggedUser() && !userIsOnboarded(this.userSession.loggedUser())) {
      configValue = environment.userSession.inactivityTimeout.onboarding;
    }

    switch (format) {
      case InactivityTimeoutFormat.miliseconds:
        return configValue * 60000;
      case InactivityTimeoutFormat.seconds:
        return configValue * 60;
      case InactivityTimeoutFormat.minutes:
      default:
        return configValue;
    }
  }

  private buildProductCustodianConfiguration(configuration: CustodianProductEnvironmentConfiguration): CustodianProductEnvironmentConfiguration {
    configuration.deposit.monthlyMaximum = configuration.deposit.annualMaximum / this.monthsInAYear;
    return configuration;
  }

  private getProductConfiguration(productType: ProductType)
  {
    const configuration = environment.products[camelCase(productType)];

    if (configuration === undefined) {
      throw new Error('Key environment.products.' + productType + ' not found');
    }

    return configuration;
  }
}
