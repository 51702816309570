import {first} from 'lodash';
export type Roles = 'ROLE_ADMIN' |'ROLE_USER';

export interface UserInvitation {
  id: string;
  code: string;
  planId: string;
}

export interface User {
  id: string;
  availableInvitations: number;
  credentialsExpired: boolean;
  email: string;
  emailConfirmedAt: string; //"2021-11-18T12:45:30"
  enabled: boolean;
  expired: boolean;
  firstName: string;
  invitations: UserInvitation[];
  surname: string;
  hasPassword: boolean;
  locked: boolean;
  phone: string;
  registeredAt: string; // "2016-05-28T14:44:31"
  roles: Roles[];
  usedInvitations: number;
  username: string;
  onBoardedAt: string | null;
  remoteCustomers: Array<any>;
  isCompanyManager: boolean;
}

export const userIsOnboarded = (user: User | null): boolean => user?.onBoardedAt != null;
export const userHasPassword = (user: User | null): boolean => user?.hasPassword;
export const getUserInvitation = (user: User, portfolioId: string): UserInvitation | null => {
  if (user === null || user === undefined ) {
    return null;
  }

  return first(user.invitations.filter((invitation => invitation.planId === portfolioId)));
};
