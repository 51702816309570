import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {WebComponent} from '../web.component';

type Theme = 'primary' | 'light' | 'success' | 'error' | 'premium';

@Component({
  selector: 'app-page-header',
  templateUrl: 'page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebappPageHeaderComponent extends WebComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() theme: Theme = 'primary';
  @Input() titleColor: string;

  constructor() {
    super();
  }
}
