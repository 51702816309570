import {Component, HostListener, Input} from '@angular/core';
import {WebComponent} from 'src/app/ui/webapp/component/web.component';

type ButtonExpand = 'full' | 'block';
type ButtonType = 'button' | 'submit';
type ButtonSize = 'sm' | 'md' | 'xs';
type TextStyle = 'default' | 'uppercase' | 'lowercase';
type TextSize = 'xs' | 'sm' | 'lg' | 'xl';
type ButtonBorder = 'default' | 'rounded' | 'none';
type BorderColor = 'primary' | 'secondary' | 'none';
type ButtonColor = 'primary' | 'white' | 'gray' | 'success' | 'premium';
type ButtonColorHover = 'primary' | 'secondary' | 'tertiary' | 'gray' | 'success' | 'premium';
type TextColor = 'primary' | 'secondary' | 'tertiary' | 'white' | 'black';
type TextColorHover = 'primary' | 'secondary' | 'tertiary' | 'white' | 'black';

@Component({
  selector: 'app-button',
  template: `
    <button
      [disabled]="disabled"
      [type]="btnType"
      class="min-w-[6rem] cursor-pointer inline-block text-center font-sans no-underline text-base focus:shadow-lg transition ease-linear duration-100 disabled:bg-gray-200 disabled:text-white"
      [ngClass]="{
        'active:shadow-inner-btn': disabled === false,
        'w-full': expand === 'full',
        'p-4': size === 'md',
        'p-2': size === 'sm',
        'p-1': size === 'xs',
        'uppercase': textStyle === 'uppercase',
        'lowercase': textStyle === 'lowercase',
        'text-sm': textSize === 'sm',
        'text-xs': textSize === 'xs',
        'text-lg': textSize === 'lg',
        'text-xl': textSize === 'xl',
        'rounded-default': rounded === 'default',
        'rounded-full': rounded === 'rounded',
        'rounded-none': rounded === 'none',
        'border-none': borderColor === 'none',
        'border border-solid': ['primary', 'secondary'].includes(borderColor) && disabled === false,
        'border-button-primary-border': borderColor === 'primary' && disabled === false,
        'border-button-secondary-border': borderColor === 'secondary' && disabled === false,
        'bg-button-primary': bgColor === 'primary',
        'bg-white': bgColor === 'white',
        'bg-gray-100': bgColor === 'gray',
        'bg-button-success': bgColor === 'success',
        'bg-button-premium': bgColor === 'premium',
        'hover:bg-button-primary-hover active:bg-button-primary-hover focus:bg-button-primary-hover': bgHover === 'primary' && disabled === false,
        'hover:bg-button-secondary-hover active:bg-button-secondary-hover focus:bg-button-secondary-hover': bgHover === 'secondary' && disabled === false,
        'hover:bg-button-tertiary-hover active:bg-button-tertiary-hover focus:bg-button-tertiary-hover': bgHover === 'tertiary' && disabled === false,
        'hover:bg-gray-200 active:bg-gray-200 focus:bg-gray-200': bgHover === 'gray' && disabled === false,
        'hover:bg-button-success-hover active:bg-button-success-hover focus:bg-button-success-hover': bgHover === 'success' && disabled === false,
        'hover:bg-button-premium-hover active:bg-button-premium-hover focus:bg-button-premium-hover': bgHover === 'premium' && disabled === false,
        'text-button-primary-text': textColor === 'primary',
        'text-button-secondary-text': textColor === 'secondary',
        'text-button-tertiary-text': textColor === 'tertiary',
        'text-white': textColor === 'white',
        'text-black': textColor === 'black',
        'hover:text-button-primary-text-hover focus:text-button-primary-text-hover': textColorHover === 'primary',
        'hover:text-button-secondary-text-hover focus:text-button-secondary-text-hover': textColorHover === 'secondary',
        'hover:text-button-tertiary-text-hover focus:text-button-tertiary-text-hover': textColorHover === 'tertiary',
        'hover:text-white focus:text-white': textColorHover === 'white',
        'hover:text-black focus:text-black': textColorHover === 'black'
      }"
    >
      <ng-content></ng-content>
    </button>
  `,
})

export class ButtonComponent extends WebComponent {
  @Input() disabled = false;
  @Input() expand: ButtonExpand = 'full';
  @Input() btnType: ButtonType = 'button';
  @Input() size: ButtonSize = 'md';
  @Input() textStyle: TextStyle = 'default';
  @Input() textSize: TextSize = 'sm';
  @Input() rounded: ButtonBorder = 'default';
  @Input() borderColor: BorderColor = 'none';
  @Input() bgColor: ButtonColor = 'primary';
  @Input() bgHover: ButtonColorHover = 'primary';
  @Input() textColor: TextColor = 'primary';
  @Input() textColorHover: TextColorHover = 'primary';

  recentlyClicked = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @HostListener('click') onClick(event: any) {
    this.preventDoubleClick(event);
  }

  private preventDoubleClick(event: any): void {
    if (this.recentlyClicked) {
      event.stopPropagation();
    }

    this.recentlyClicked = true;

    setTimeout(() => {
      this.recentlyClicked = false;
    }, 1500);
  }
}
