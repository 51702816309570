import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn} from '@angular/forms';
import {pick} from 'lodash';

export interface Validator {
  key: string;
  validators: ValidatorFn[];
}

@Injectable({
  providedIn: 'root'
})
export class AppFormBuilder {

  constructor(private builder: FormBuilder) {
  }

  build(command: object, validators: Validator[]): FormGroup {
    const formCommand = {};

    Object.keys(command).forEach((key) => {
      const value = command[key];
      const validatorFns = this.getValidator(key, validators);
      formCommand[key] = [value, validatorFns];
    });

    return this.builder.group(formCommand);
  }

  public subtract(form: FormGroup, fields: string[]): FormGroup {
    const childForm: FormGroup = this.build(pick(form.value, fields), []);
    fields.forEach(field => childForm.setControl(field, form.get(field)));
    return childForm;
  }

  private getValidator(key: string, validators: Validator[]): ValidatorFn[] {
    const validator = validators.find(validatorNode => validatorNode.key === key);

    if (!validator) {
      return [];
    }

    return validator.validators;
  }

}
