import Rollbar from 'rollbar';

export default {
  rollbar: {
    accessToken: 'a89aac7923704d79b5b1cebfe98e0c22',
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
  } as Rollbar.Configuration,
  userSession: {
    closeSessionOnEveryPageReload: false,
    inactivityTimeout: { // Inactivity timeout (minutes)
      default: 5,
      onboarding: 240
    }
  },
  externalLogin: {
    port: null
  },
  analytics: {
    tealium: {
      enabled: false
    },
    customerIoSiteId: null
  },
  appStore: {
    appPageLink: {
      android: 'https://play.google.com/store/apps/details?id=com.finizens.app',
      ios: 'https://apps.apple.com/es/app/finizens/id1243555874'
    }
  }
};
