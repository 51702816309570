<div class="p-4">
  <div class="flex justify-end">
    <ion-icon name="close" (click)="confirm()"></ion-icon>
  </div>
  <div>
    <div class="flex justify-center">
      <h1>Estas en un entorno de STAGING</h1>
    </div>
  </div>
  <p class="flex justify-center text-center p-5">
    <app-filled-button size="sm" textSize="sm" (click)="confirm()">ACEPTAR</app-filled-button>
  </p>
</div>
