import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {InternalServerErrorPage} from 'src/app/ui/webapp/page/error/internal-server/internal-server-error.page';
import {NotFoundErrorPage} from 'src/app/ui/webapp/page/error/not-found/not-found-error.page';
import {WebappLayoutPage} from './webapp/webapp-layout.page';

const routes: Routes = [
  {
    path: '',
    component: WebappLayoutPage,
    loadChildren: () => import('./webapp/webapp.module').then(m => m.WebappModule)
  },
  {
    path: '500',
    component: InternalServerErrorPage
  },
  {
    path: '404',
    component: NotFoundErrorPage
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRouting {
}
