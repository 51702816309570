import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {Router} from 'src/app/infrastructure/router/router';
import {WebComponent} from 'src/app/ui/webapp/component/web.component';

@Component({
  template: ``
})

export abstract class WebPage extends WebComponent {
  public title: string;

  constructor(
      protected readonly router: Router,
      protected appEnvironment: AppEnvironmentService,
      protected titleService: Title,
  ) {
    super();
  }

  canDeactivate(): boolean {
    return true;
  }

  public goBack(): void {
    this.router.navigateToBack();
  }

  protected navigate(route: string): void {
    this.router.navigate([route]);
  }

  protected navigateToRelative(route: string, activatedRoute: ActivatedRoute): void {
    this.router.navigate(['../' + route], {relativeTo: activatedRoute});
  }

  protected changeUrl(route: string): void {
    this.router.changeUrl(route);
  }

  protected currentUrl(): string {
    return this.router.currentUrl().url;
  }

  protected onInit() {
    super.onInit();
    this.titleService.setTitle(this.title || this.appEnvironment.appName());
  }
}
