import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {Onboarding} from '../model/onboarding.model';
import {Localstorage} from '../service/storage/localstorage';

export interface OnboardingState {
  onboarding: Onboarding | null;
}

export const ONBOARDING_NAMESPACE = '__onboarding__';

const emptyState = () => ({
  onboarding: null,
});

@Injectable({
  providedIn: 'root',
})
export default class OnboardingStore extends ComponentStore<OnboardingState> {
  public readonly onboarding$ = this.select(state => state.onboarding);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveOnboarding(onboarding: Onboarding): void {
    this.patchState(() => ({
      onboarding
    }));
    this.localStorage.set(ONBOARDING_NAMESPACE, JSON.stringify(onboarding));
  }

  syncOnboarding(): Onboarding {
    return this.get().onboarding;
  }

  reset(): void {
    this.setState(() => (emptyState()));
    this.localStorage.unset(ONBOARDING_NAMESPACE);
  }
}
