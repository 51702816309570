<div class="h-screen p-20 text-center text-white bg-primary">
  <div class="max-w-md mx-auto">
    <div class="w-full p-10">
      <app-logo class="flex-1 max-w-[300px]"></app-logo>
    </div>

    <div class="font-bold md:text-3xl">
      {{ 'shared.errors.connectionProblem' | translate }}
    </div>

    <div class="py-5 font-bold md:text-3xl">
      {{ 'shared.errors.tryAgainLater' | translate }}
    </div>

    <app-link (click)="refresh()">
      {{ 'shared.errors.tryAgainNow' | translate }}
    </app-link>
  </div>
</div>
