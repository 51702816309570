import {Inject, Injectable, Injector} from '@angular/core';
import {FileOpener} from '@capacitor-community/file-opener';
import {Capacitor} from '@capacitor/core';
import {Directory, FileInfo, Filesystem} from '@capacitor/filesystem';
import saveAs from 'file-saver';
import {isNil} from 'lodash';
import {Toast} from 'src/app/infrastructure/service/toast.service';
import {Translator} from 'src/app/infrastructure/translation/translator.service';

export const PLATFORM = {
  ios: 'ios',
  android: 'android',
  web: 'web'
} as const;

export const FILE_EXTENSION = {
  pdf: 'pdf',
} as const;

export const DIRNAME = 'documentacion_contractual';

@Injectable({
  providedIn: 'root',
})
export class FileSaver {

  constructor(
    private toast: Toast,
    @Inject(Injector) private readonly injector: Injector
  ) {}

  private get translator() {
    return this.injector.get(Translator);
  }

  async presentToast(
    message: string,
  ) {
    await this.toast.present({
      message,
      position: 'top',
      duration: 10000,
      buttons: [
        {
          text: 'x',
          role: 'cancel',
        }
      ]
    });
  }

  async saveDocument(data: Blob, filename: string, extension = FILE_EXTENSION.pdf): Promise<void> {
    if (Capacitor.getPlatform() === PLATFORM.web) {
      this.downloadFromBrowser(data, filename);
      return;
    }

    const data64 = await this.convertBlodToBase64(data) as string;
    const directory = Directory.Documents;

    try {
      await Filesystem.requestPermissions();
      filename = await this.getFileName(filename, directory, extension);
      await Filesystem.writeFile({
        path: DIRNAME + '/' + filename,
        data: data64,
        directory
      });
      const path = await Filesystem.getUri({
        directory,
        path: DIRNAME + '/' + filename,
      });

      FileOpener.open({
        filePath: path.uri,
        contentType: 'application/' + extension,
        openWithDefault: true
      });

      this.presentToast(this.translator.trans('fileSaver.success'));
    } catch (e) {
      const errorMessage = this.translator.trans('fileSaver.error.write');
      this.presentToast(errorMessage + '. ' + e.message);
    }
  }

  private convertBlodToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  private async getFileName(filename: string, directory: Directory, extension: string): Promise<string> {
    try {
      try {
        await Filesystem.mkdir({
          path: DIRNAME,
          directory
        });
      } catch (e) {}

      const ret = await Filesystem.readdir({
        path: DIRNAME,
        directory
      });

      let i = 1;
      let newFileName = filename + '.' + extension;

      while (this.fileExistsInDir(newFileName, ret.files)) {
        newFileName = filename + '_' + i++ + '.' + extension;
      }

      return newFileName;
    }
    catch(e) {
      const errorMessage = this.translator.trans('fileSaver.error.read');
      this.presentToast(errorMessage + '. ' + e.message);
      throw e;
    }
  }

  private fileExistsInDir(item: string, list: FileInfo[]): boolean {
    return !isNil(list.find((fileInDir) => item === fileInDir.name));
  }

  private downloadFromBrowser(data: string | Blob, filename: string) {
    saveAs(data, filename);
  }
}
