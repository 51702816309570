import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ComponentRef, FrameworkDelegate, Mode} from '@ionic/core';
import {isNil} from 'lodash';
import {ClassOf, PropsOf} from 'src/app/domain/function/type.helper';

export interface ModalOptions<T> {
  component: ClassOf<T>;
  componentProps?: PropsOf<T>;
  presentingElement?: HTMLElement;
  showBackdrop?: boolean;
  backdropDismiss?: boolean;
  cssClass?: string | string[];
  delegate?: FrameworkDelegate;
  breakpoints?: number[];
  initialBreakpoint?: number;
  animated?: boolean;
  swipeToClose?: boolean;
  mode?: Mode;
  keyboardClose?: boolean;
  id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class Modal {
  private modal: HTMLIonModalElement | null;

  constructor(private readonly modalCtrl: ModalController) {
  }

  async present<T>(options: ModalOptions<T>): Promise<HTMLIonModalElement> {
    this.modal = null;
    this.initializeDefaultOptions(options as any);

    const modal = await this.modalCtrl.create(options as any);

    await modal.present();

    this.modal = modal;

    const onDismiss = () => {
      this.modal.removeEventListener('willDismiss',onDismiss);
      this.modal = null;
    };

    this.modal.addEventListener('willDismiss', onDismiss);
    return modal;
  }

  isOpen(): boolean {
    return !isNil(this.modal);
  }

  async dismiss(data?: any) {
    await this.modalCtrl.dismiss(data);
  }

  private initializeDefaultOptions(options: ModalOptions<ComponentRef>) {
    if (options.breakpoints === undefined) {
      options.breakpoints = [0.1, 0.5, 1];
    }
    if (options.cssClass === undefined) {
      options.cssClass = 'p-5 rounded bg-black-100';
    }
  }
}
