import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {CreateCustomerFromLead, CreatePotentialAmount, CreateTolerance, UpdateCustomer} from 'src/app/domain/command/customer/customer.command';
import {UploadVat} from 'src/app/domain/command/vat.command';
import {toFormData} from 'src/app/domain/function/form-data.helper';
import {objKeysToSnakeCase} from 'src/app/domain/function/object.helper';
import {Customer} from 'src/app/domain/model/customer/customer.model';
import CustomerStore from 'src/app/domain/store/customer/customer.store';
import {ApiClient} from 'src/app/infrastructure/http/api.client';

@Injectable({
  providedIn: 'root',
})
export default class CustomerApi {
  private readonly url = 'customer';
  constructor(private http: ApiClient, private store: CustomerStore) {
  }

  public async fetch(): Promise<void> {
    const customer: Customer = await this.http.get(this.url);
    this.store.save(customer[0]);
  }

  public async createFromLead(command: CreateCustomerFromLead): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.http.post(this.url, snakeCommand);
    this.fetch();
  }

  public customer(autoFetch = true): Observable<Customer | null> {
    return this.store.customer$.pipe(
      tap(stored => {
        if (!stored && autoFetch) {
          this.fetch();
        }
      }),
      filter(stored => !!stored)
    );
  }

  public syncCustomer(): Customer | null {
    return this.store.syncCustomer();
  }

  public async waitFetchAndSync(): Promise<Customer> {
    await this.fetch();
    return this.syncCustomer();
  }

  public async updatePotentialAmount(command: CreatePotentialAmount): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.http.put(this.url + '/' + command.customerId + '/potential-amounts', snakeCommand);
    this.fetch();
  }

  public async updateTolerance(command: CreateTolerance): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.http.put(this.url + '/' + command.customerId + '/tolerance', snakeCommand);
    this.fetch();
  }

  public async update(command: UpdateCustomer): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.http.put(this.url + '/' + command.id, snakeCommand);
    this.fetch();
  }

  public async uploadVat(command: UploadVat): Promise<void> {
    const user = await this.waitFetchAndSync();
    const formData = toFormData(command);

    await this.http.post(this.url + '/' + user.id + '/vat', formData);
    await this.fetch();
  }
}
