import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {Customer} from '../../model/customer/customer.model';

export interface CustomerState {
  customer: Customer | null;
}

const emptyState = () => ({
  customer: null,
});

@Injectable({
  providedIn: 'root',
})
export default class CustomerStore extends ComponentStore<CustomerState> {
  public readonly customer$ = this.select(state => state.customer);

  constructor() {
    super(emptyState());
  }

  save(customer: Customer): void {
    this.patchState(() => ({
      customer
    }));
  }

  syncCustomer(): Customer | null {
    return this.get().customer;
  }

  reset(): void {
    this.setState(() => (emptyState()));
  }
}
