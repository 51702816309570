import {defer, isNil} from 'lodash';
import {Fn, Subscribeable} from 'src/app/domain/function/type.helper';

export const toPromise = async <T>(subscribeable: Subscribeable<T>, resolveOn?: Fn<boolean, [T]>): Promise<T> => new Promise(resolve => {
    const subscription = subscribeable.subscribe((value) => {
      if (isNil(resolveOn) || resolveOn(value)) {
        defer(() => subscription.unsubscribe());
        resolve(value);
      }
    });
  });
