import {Translatable} from 'src/app/domain/service/translator/translatable';

export const ERROR_CODES = {
  pensionPlanExceeded: 'pensionPlanExceeded',
  invalidData: 'invalidData',
  invalidFileExtension: 'invalidFileExtension',
  invalidVat: 'invalidVat',
  invalidName: 'invalidName',
  invalidChildAge: 'invalidChildAge',
  invalidCheckEmail: 'invalidCheckEmail',
  invalidCheckIban: 'invalidCheckIban',
  invalidCheckPassword: 'invalidCheckPassword',
  invalidZipCode: 'invalidZipCode',
  required: 'required',
  invalidPassword: 'invalidPassword',
  minDaysRequiredForChangePortfolio: 'minDaysRequiredForChangePortfolio',
  portfolioNotAvailable: 'portfolioNotAvailable',
  toleranceExceded: 'toleranceExceded',
  invalidProductId: 'invalidProductId',
  sameAsTheCurrent: 'sameAsTheCurrent',
  hasActiveRebalance: 'hasActiveRebalance',
  expectingIncomingTransfer: 'expectingIncomingTransfer',
  kycAlreadyCompleted: 'kycAlreadyCompleted',
  termsAccepted: 'termsAccepted',
} as const;

export type ErrorCode = typeof ERROR_CODES[keyof typeof ERROR_CODES];
export type ErrorMapping = { [k in ErrorCode]?: Translatable };
