import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {KycStep} from '../model/kyc-step.model';
import {Localstorage} from '../service/storage/localstorage';

export interface KycStepState {
  kycStep: KycStep | null;
}

export const KYC_STEP_NAMESPACE = '__kyc_step__';

const emptyState = () => ({
  kycStep: null,
});

@Injectable({
  providedIn: 'root',
})
export default class KycStepStore extends ComponentStore<KycStepState> {
  public readonly kycStep$ = this.select(state => state.kycStep);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveKycStep(kycStep: KycStep): void {
    this.patchState(() => ({
      kycStep
    }));
    this.localStorage.set(KYC_STEP_NAMESPACE, JSON.stringify(kycStep));
  }

  syncKycStep(): KycStep | null {
    return this.get().kycStep;
  }

  reset(): void {
    this.setState(() => (emptyState()));
    this.localStorage.unset(KYC_STEP_NAMESPACE);
  }
}

