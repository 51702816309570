import {isEmpty, isNil} from 'lodash';
import {Fn, Index, SortFn} from 'src/app/domain/function/type.helper';

export const not = <T extends Array<any> = [any?]>(fn: Fn<boolean, T>): Fn<boolean, T> => (...args: T) => !fn(...args);

export const notNil = (value: any) => !isNil(value);

export const notEmpty = (value: any) => !isEmpty(value);

export const sortIndex = <T>(index: Index<T>, sortFn: SortFn<[T, string | number]>): Index<T> =>
  Object.entries(index)
    .sort((a, b) => sortFn([a[1], a[0]], [b[1], b[0]]))
    .reduce((a, [bKey, bValue]) => ({...a, [bKey]: bValue}), {});
