import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Localstorage} from 'src/app/domain/service/storage/localstorage';

export const INACTIVITY_NAMESPACE = '__inactivity__';

export interface InactivityStatusState {
  lastInteraction: Date | null;
}

const emptyState = () => ({
  lastInteraction: null,
});

@Injectable({
  providedIn: 'root',
})
export default class InactivityStatusStore extends ComponentStore<InactivityStatusState> {
  public readonly status$: Observable<InactivityStatusState> = this.select(state => state);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  save(lastInteraction: Date): void {
    this.patchState(() => ({lastInteraction}));
    this.localStorage.syncSet(INACTIVITY_NAMESPACE, JSON.stringify(this.get()));
  }

  sync(): InactivityStatusState {
    return this.get();
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
    this.localStorage.unset(INACTIVITY_NAMESPACE);
  }
}
