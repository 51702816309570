import {Component} from '@angular/core';

@Component({
  selector: 'app-webapp-layout',
  template: `
    <router-outlet></router-outlet>
  `,
  host: {
    class: 'text-current overflow-y-auto'
  }
})
export class WebappLayoutPage {

  constructor() {}

}
