import {Inject, Injectable} from '@angular/core';
import Rollbar from 'rollbar';
import {userIsOnboarded} from 'src/app/domain/model/user.model';
import CustomerAreaRouteResolver from 'src/app/domain/service/route-resolver/customer-area-route-resolver.service';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {UserSession} from 'src/app/infrastructure/environment/user-session';
import { Route } from 'src/app/infrastructure/router/router';
import {RollbarService} from 'src/app/infrastructure/service/error-handler/RollbarErrorHandler.service';
import OperationalBlockRouteResolver from 'src/app/domain/service/route-resolver/operational-block-route-resolver.service';
import {Customer, hasOperationalBlockReasons} from 'src/app/domain/model/customer/customer.model';
import CustomerApi from 'src/app/domain/service/api/customer/customer.api';

@Injectable({
  providedIn: 'root',
})

export default class RouteResolverService {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private userSession: UserSession,
    private appEnvironment: AppEnvironmentService,
    private readonly customerAreaResolver: CustomerAreaRouteResolver,
    private readonly operationalBlockResolver: OperationalBlockRouteResolver,
    private readonly customerApi: CustomerApi,
  ) {
  }

  public resolve(fromUrl?: Route): string {
    if (!this.userSession.isLogged()) {
      return this.resolveAuthUrl(fromUrl);
    }

    if (userIsOnboarded(this.userSession.loggedUser())) {
      const customer: Customer = this.customerApi.syncCustomer();

      if (hasOperationalBlockReasons(customer)) {
        return this.resolveOperationalBlockUrl(fromUrl);
      }

      return this.resolveCustomerAreaUrl(fromUrl);
    }

    return this.resolveOnboardingUrl(fromUrl);
  }

  public resolveErrorUrl(): string {
    return '500';
  }

  public resolveWebUrl(path = ''): string {
    if (!path.startsWith('/')) {
      path = '/' + path;
    }

    return this.appEnvironment.webUrl() + path;
  }

  public resolveRegisterUrl(): string {
    return '/auth/register';
  }

  public resolveRecoverUrl(): string {
    return '/auth/recover';
  }

  private resolveAuthUrl(fromUrl?: Route): string {
    if (fromUrl?.url === '/simulator/lead') {
      return 'auth/register';
    }

    if (this.appEnvironment.isLoginProviderExternal()) {
      return 'auth/no-session';
    }

    return 'auth/login';
  }

  private resolveCustomerAreaUrl(fromUrl?: Route): string {
    return this.customerAreaResolver.resolveCustomerArea();
  }

  private resolveOnboardingUrl(fromUrl?: Route): string {
    return 'onboarding';
  }

  private resolveOperationalBlockUrl(fromUrl?: Route): string {
    return this.operationalBlockResolver.resolveSummaryRoute();
  }
}
