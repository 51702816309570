import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {BackofficePortfolio} from '../model/backoffice-portfolio/backoffice-portfolio.model';

export interface BackofficePortfolioState {
  portfolios: BackofficePortfolio[];
}

const emptyState = () => ({
  portfolios: []
});

@Injectable({
  providedIn: 'root',
})
export default class BackofficePortfolioStore extends ComponentStore<BackofficePortfolioState> {
  public readonly portfolios$ = this.select(state => state.portfolios);

  constructor() {
    super(emptyState());
  }

  saveAll(portfolios: BackofficePortfolio[]): void {
    this.patchState(() => ({
      portfolios
    }));
  }

  sync(): BackofficePortfolio[] | null {
    return this.get().portfolios;
  }
}
