import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {getStyle} from 'chart.js/helpers';
import StyleStore from 'src/app/infrastructure/store/style.store';

export interface MenuStatusState {
  opened: boolean;
}

const emptyState = () => ({
  opened: false,
});

@Injectable({
  providedIn: 'root',
})
export default class MenuStatusStore extends ComponentStore<MenuStatusState> {
  public readonly opened$ = this.select(state => state.opened);

  constructor( public styleStore: StyleStore) {
    super(emptyState());
  }

  saveOpen(opened: boolean): void {
    const style = this.styleStore.sync();
    style.opacityOn = opened;
    this.styleStore.save(style);
    this.patchState(() => ({
      opened
    }));
  }

  syncOpened(): boolean {
    return this.get().opened;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
  }
}
