import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import ValidationError from 'src/app/domain/error/validation-error';
import {sleep} from 'src/app/domain/function/promise.helper';
import {uuid} from 'src/app/domain/function/uuid.helper';

interface SubscriptionData {
  id: string;
  subscription: Subscription;
}

@Component({
  template: ''
})
export abstract class WebComponent implements OnInit, OnDestroy {
  private subscriptions: SubscriptionData[] = [];

  ngOnInit(): void {
    this.onInit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.subscription.unsubscribe());

    this.onDestroy();
  }

  protected onInit(): void {

  }

  protected onDestroy(): void {

  }

  protected subscribe<T>(
    observable: Observable<T>,
    next?: (value: T) => void,
    error?: (value: Error | ValidationError) => void,
    complete?: () => void
  ): string {
    const id = uuid();
    this.subscriptions.push({
      id,
      subscription: observable.subscribe({next, error, complete})
    });

    return id;
  }

  protected unsubscribe(subscriptionId: string): void {
    const subscription = this.findSubscription(subscriptionId);

    if (!subscription || subscription.closed) {
      return;
    }

    subscription.unsubscribe();
  }

  protected findSubscription(subscriptionId: string): Subscription | null {
    return this.subscriptions.find((subs) => subs.id === subscriptionId)?.subscription;
  }

  protected async forceChangeDetection(ms?: number): Promise<void> {
    await sleep(ms || 1);
  }
}
