import {Component, Input} from '@angular/core';
import {LogoColor} from 'src/app/ui/shared/component/logo/logo-color';
import {WebComponent} from 'src/app/ui/webapp/component/web.component';

@Component({
  selector: 'app-caser-ul-logo',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" style="min-height: 30px;" viewBox="0 0 300 110">
      <g id="a"/>
      <g id="b">
        <g id="c">
          <g>
            <g>
              <path
                [ngClass]="{
                  'fill-white': color === 'light',
                  'fill-gray-500': color === 'grey',
                  'fill-black': color === 'dark',
                  'fill-[#007167]': color === 'primary'
                }"
                fill="#007167"
                d="M131.26,17.56c0-11.58-7.06-16.22-18.43-16.22-5.9,0-10.85,.84-16.33,2.42V14.61c5.27-2.42,10.32-3.37,15.38-3.37s7.69,2.42,7.69,6.43v1.9c-18.54,.53-26.54,8.53-26.54,18.33s6.11,15.27,18.33,15.27c7.16,0,11.69-1.37,20.12-1.37-.32-2.63-.53-7.16-.53-11.27,0-7.79,.32-14.85,.32-22.96Zm-12.11,23.28c-.42,1.47-2.42,2.63-6.63,2.63-4.53,0-7.48-2.63-7.48-6.43,0-5.58,4.95-8.53,14.54-8.85,0,6.32-.11,11.06-.42,12.64Zm30.95-25.39c0-2.52,2.52-4.21,6.53-4.21,3.37,0,6.85,.53,11.8,2.53V2.81c-4.21-1.16-7.48-1.48-11.37-1.48-11.8,0-18.96,5.9-18.96,15.38,0,14.43,20.75,13.17,20.75,21.49,0,3.27-2.84,5.37-8.43,5.37-3.37,0-8.11-.63-12.11-2v10.32c4.11,1.26,8.53,1.69,12.85,1.69,13.69,0,19.59-7.9,19.59-16.11,0-15.17-20.64-14.43-20.64-22.02ZM199.3,1.34c-13.8,0-23.8,9.8-23.8,25.7,0,18.43,10.64,26.54,25.49,26.54,3.48,0,8.53-.42,12.85-1.79v-10.32c-4,1.79-8.54,2.21-12.33,2.21-8.11,0-13.58-5.16-13.58-13.27v-1.58h29.6c.42-2.95,.52-5.16,.52-6.95,0-11.69-5.69-20.54-18.75-20.54Zm4.43,18.33h-14.75c.84-5.06,4.11-8.74,9.69-8.74,5.16,0,7.37,2.74,7.37,6.53,0,1.37-.74,2.21-2.32,2.21ZM52.26,27.46c0,17.38,10.11,26.23,25.28,26.23,2.84,0,5.58-.32,8.74-.95v-10.11c-2.53,.42-4.84,.74-8.21,.74-8.01,0-13.06-4.53-13.06-15.9s5.05-15.59,12.85-15.59c3.48,0,5.69,.32,8.43,1.16V2.28c-2.74-.63-5.48-.84-8.74-.84-16.22,0-25.28,10.11-25.28,26.02ZM247.84,1.16c-10.91,0-12.19,2.46-22.68,2.46V52.6h11.77V16.02c0-2.67,2.99-4.38,8.13-4.38,.49,0,.97,.02,1.43,.06,2.38,.16,6.1,.65,9.16,1.56V2.37c-2.41-.84-5.07-1.22-7.81-1.22Z"
              />
              <path
                [ngClass]="{
                  'fill-white': color === 'light',
                  'fill-gray-500': color === 'grey',
                  'fill-black': color === 'dark',
                  'fill-[#ec7700]': color === 'primary'
                }"
                fill="#ec7700"
                d="M30.02,49.54c4.79,0,14.13,.98,18.27,2.75-12.96-14.36-9.78-39.21,0-52.29C20.35,8.47,0,29.78,0,60.49c0,.68,.01,1.35,.03,2.02,5.87-10.5,17.11-12.97,29.99-12.97Z"
              />
            </g>
            <g>
              <g>
                <path
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  d="M13.64,84.92h13.19c0,1.36-.06,2.59-.17,3.7-.11,1.11-.34,2.14-.68,3.09-.47,1.33-1.12,2.52-1.93,3.54-.81,1.03-1.77,1.89-2.87,2.58-1.1,.69-2.3,1.22-3.61,1.58-1.31,.36-2.69,.54-4.14,.54-1.99,0-3.8-.33-5.44-.98-1.64-.66-3.05-1.58-4.22-2.76-1.18-1.19-2.09-2.61-2.75-4.27-.66-1.66-.98-3.5-.98-5.51s.32-3.81,.97-5.48c.64-1.66,1.56-3.08,2.75-4.26,1.19-1.18,2.62-2.09,4.29-2.75,1.67-.66,3.54-.98,5.6-.98,2.67,0,5.01,.58,7.02,1.73,2.01,1.15,3.61,2.94,4.78,5.36l-6.31,2.61c-.59-1.4-1.35-2.41-2.29-3.02-.94-.61-2.01-.92-3.2-.92-1,0-1.9,.19-2.71,.56-.81,.37-1.51,.9-2.09,1.59-.58,.69-1.03,1.52-1.36,2.49-.33,.97-.49,2.06-.49,3.26,0,1.09,.14,2.09,.42,3.02,.28,.93,.71,1.73,1.27,2.41,.56,.68,1.27,1.2,2.1,1.58,.84,.37,1.81,.56,2.92,.56,.66,0,1.29-.07,1.9-.22,.61-.15,1.16-.38,1.64-.71,.49-.33,.89-.75,1.2-1.27,.32-.52,.53-1.15,.64-1.9h-5.46v-5.15Z"
                />
                <path
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  d="M29.1,81.84h6.14v2.85c.66-1.04,1.46-1.84,2.41-2.39,.95-.55,2.06-.83,3.32-.83h.53c.19,0,.41,.02,.66,.07v5.87c-.81-.41-1.7-.61-2.64-.61-1.42,0-2.49,.42-3.2,1.27-.71,.85-1.07,2.09-1.07,3.71v7.46h-6.14v-17.4Z"
                />
                <path
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  d="M49.65,81.84v9.8c0,2.15,1.04,3.22,3.12,3.22s3.12-1.07,3.12-3.22v-9.8h6.14v10.99c0,2.35-.77,4.11-2.32,5.29-1.55,1.17-3.86,1.76-6.93,1.76s-5.39-.59-6.93-1.76c-1.55-1.18-2.32-2.94-2.32-5.29v-10.99h6.14Z"
                />
                <path
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  d="M70.98,107.91h-6.14v-26.08h6.14v1.93c1.31-1.65,3.1-2.48,5.36-2.48,1.24,0,2.4,.24,3.48,.73,1.07,.49,2.01,1.15,2.8,1.98,.79,.84,1.41,1.82,1.85,2.95,.44,1.13,.66,2.34,.66,3.63s-.22,2.49-.66,3.59c-.44,1.11-1.05,2.08-1.83,2.92-.78,.84-1.7,1.49-2.76,1.97-1.06,.47-2.22,.71-3.46,.71-2.17,0-3.98-.75-5.43-2.24v10.38Zm7.73-17.33c0-.54-.1-1.06-.29-1.54-.19-.49-.47-.91-.83-1.27-.36-.36-.79-.65-1.27-.86-.49-.21-1.01-.32-1.58-.32s-1.05,.1-1.53,.31c-.47,.2-.89,.49-1.24,.85-.35,.36-.63,.79-.85,1.27-.22,.49-.32,1-.32,1.54s.1,1.05,.31,1.53c.2,.47,.49,.89,.85,1.24,.36,.35,.79,.63,1.27,.85,.49,.21,1,.32,1.54,.32s1.05-.1,1.53-.31c.47-.2,.89-.49,1.24-.85,.35-.36,.63-.78,.85-1.25,.21-.47,.32-.97,.32-1.49Z"
                />
                <path
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  d="M86.58,90.38c0-1.29,.26-2.49,.78-3.61,.52-1.12,1.24-2.09,2.17-2.92,.93-.82,2.03-1.48,3.31-1.95,1.28-.47,2.67-.71,4.19-.71s2.88,.23,4.15,.69c1.28,.46,2.38,1.11,3.32,1.93,.94,.83,1.67,1.81,2.19,2.95,.52,1.14,.78,2.4,.78,3.78s-.27,2.64-.8,3.78c-.53,1.14-1.26,2.13-2.19,2.95-.93,.83-2.04,1.46-3.34,1.92-1.3,.45-2.71,.68-4.22,.68s-2.87-.23-4.14-.68c-1.27-.45-2.36-1.1-3.27-1.93-.92-.84-1.63-1.84-2.15-3-.52-1.16-.78-2.46-.78-3.88Zm6.48,.07c0,.59,.11,1.12,.32,1.61,.21,.49,.5,.91,.85,1.27,.35,.36,.77,.64,1.25,.85,.49,.2,1,.31,1.54,.31s1.06-.1,1.54-.31c.49-.2,.9-.49,1.25-.85,.35-.36,.63-.78,.85-1.27,.21-.49,.32-1.01,.32-1.58s-.11-1.06-.32-1.54c-.22-.49-.5-.91-.85-1.27-.35-.36-.77-.64-1.25-.85-.49-.2-1-.31-1.54-.31s-1.06,.1-1.54,.31-.9,.49-1.25,.85c-.35,.36-.63,.78-.85,1.25s-.32,.98-.32,1.53Z"
                />
              </g>
              <g>
                <path
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  d="M157.12,88.01c-.31-1.66-1.88-2.75-3.58-2.75-1.84,0-3.37,.95-3.76,2.75h7.34Zm5.88,3.56h-13.32c0,2.51,1.36,3.86,3.97,3.86,1.36,0,2.33-.44,3.03-1.59h6.09c-1.01,4.1-5.01,5.97-9.08,5.97-5.91,0-10.33-3.25-10.33-9.25s4.07-9.29,9.88-9.29c6.19,0,9.77,3.73,9.77,9.66v.65Z"
                />
                <rect
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  x="164.84"
                  y="73.73"
                  width="6.33"
                  height="25.49"
                />
                <polygon
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  points="183.18 90.75 187.42 81.83 194.52 81.83 185.2 99.22 181.16 99.22 171.81 81.83 178.9 81.83 183.18 90.75"
                />
                <path
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  d="M206.73,88.01c-.31-1.66-1.88-2.75-3.58-2.75-1.84,0-3.38,.95-3.76,2.75h7.34Zm5.88,3.56h-13.32c0,2.51,1.36,3.86,3.97,3.86,1.36,0,2.33-.44,3.02-1.59h6.09c-1.01,4.1-5.01,5.97-9.08,5.97-5.91,0-10.33-3.25-10.33-9.25s4.07-9.29,9.88-9.29c6.19,0,9.77,3.73,9.77,9.66v.65Z"
                />
                <polygon
                  [ngClass]="{
                    'fill-white': color === 'light',
                    'fill-gray-500': color === 'grey',
                    'fill-black': color === 'dark',
                    'fill-[#1d1d1b]': color === 'primary'
                  }"
                  fill="#1d1d1b"
                  points="221.94 99.22 215.61 99.22 215.61 86.92 213.56 86.92 213.56 81.83 215.61 81.83 215.61 76.62 221.94 76.62 221.94 81.83 225.52 81.83 225.52 86.92 221.94 86.92 221.94 99.22"
                />
                <g>
                  <path
                    [ngClass]="{
                      'fill-white': color === 'light',
                      'fill-gray-500': color === 'grey',
                      'fill-black': color === 'dark',
                      'fill-[#1d1d1b]': color === 'primary'
                    }"
                    fill="#1d1d1b"
                    d="M233.06,99.22h-6.33v-17.39h6.33v17.39Zm.38-22.88c0,1.9-1.6,3.45-3.55,3.45s-3.55-1.56-3.55-3.45,1.6-3.46,3.55-3.46,3.55,1.56,3.55,3.46"
                  />
                  <path
                    [ngClass]="{
                      'fill-white': color === 'light',
                      'fill-gray-500': color === 'grey',
                      'fill-black': color === 'dark',
                      'fill-[#1d1d1b]': color === 'primary'
                    }"
                    fill="#1d1d1b"
                    d="M241.44,90.55c0,2.2,1.49,3.9,4.07,3.9s4.07-1.69,4.07-3.9-1.5-3.93-4.07-3.93-4.07,1.8-4.07,3.93m14.26,8.68h-6.33v-1.93h-.07c-1.11,1.73-3.3,2.51-5.39,2.51-5.29,0-9.01-4.3-9.01-9.29s3.65-9.26,8.94-9.26c2.05,0,4.21,.75,5.53,2.27v-1.7h6.33v17.39Z"
                  />
                </g>
              </g>
              <path
                [ngClass]="{
                  'fill-white': color === 'light',
                  'fill-gray-500': color === 'grey',
                  'fill-black': color === 'dark',
                  'fill-[#1d1d1b]': color === 'primary'
                }"
                fill="#1d1d1b"
                d="M126.16,83.48h9.12v-9.87h6.31v25.57h-6.31v-10.55h-9.12v10.55h-6.31v-25.57h6.31v9.87Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,
})
export class CaserUlLogoComponent extends WebComponent {
  @Input() color: LogoColor = 'light';
}
