import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SplashScreen} from '@capacitor/splash-screen';
import {defer} from 'lodash';
import AuthApi from 'src/app/domain/service/api/auth.api';
import {Device} from 'src/app/infrastructure/device/device';
import {InactivityHandler} from 'src/app/infrastructure/device/inactivity-handler';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {Bootstrap} from '../infrastructure/bootstrap/bootstrap';

@Component({
  selector: 'app-root',
  template: `
    <ion-app>
      <app-loader-overplay></app-loader-overplay>
      <ion-router-outlet></ion-router-outlet>
    </ion-app>
  `,
  styleUrls: ['./webapp/page/web.page.scss']
})
export class AppComponent implements OnInit {
  private isApp: boolean;

  constructor(
    private readonly bootstrap: Bootstrap,
    private readonly inactivityHandler: InactivityHandler,
    private router: Router,
    private authApi: AuthApi,
    private environment: AppEnvironmentService,
    device: Device,
  ) {
    this.isApp = device.isApp();
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event) {
    if (this.isApp) {
      this.inactivityHandler.notifyUserInteraction();
    }
  };

  @HostListener('click', ['$event'])
  onClick(event) {

    if (!this.isApp) {
      this.inactivityHandler.notifyUserInteraction();
    }
  };

  @HostListener('window:beforeunload', ['$event'])
  async beforeunloadHandler(event: Event) {
    /*
     * This code performs a logout when closing the tab,
     * in case the login in the application is done externally
     * (for example, with EVO), which will destroy the user's information.
     * This is done to avoid subsequent issues with the HttpInterceptor
     * when performing the next login from the external platform of origin.
     */
    if (this.environment.userSessionParameters().closeSessionOnEveryPageReload) {
      this.authApi.logout();
      this.inactivityHandler.notifyLogout();
    }
  }

  ngOnInit(): void {
    SplashScreen.show({
      autoHide: false
    });

    defer(async () => {
      this.bootstrap.bootstrap();
    });
  }
}
