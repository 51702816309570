/* eslint-disable @typescript-eslint/naming-convention */
import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import {isEqual, isNil, merge} from 'lodash';
import Rollbar from 'rollbar';
import {PropsOf} from 'src/app/domain/function/type.helper';
import CustomerPortfolioApi from 'src/app/domain/service/api/customer-portfolio.api';
import CustomerApi from 'src/app/domain/service/api/customer/customer.api';
import LeadApi from 'src/app/domain/service/api/lead.api';
import UserApi from 'src/app/domain/service/api/user.api';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {environment} from 'src/environments/environment';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({providedIn: 'root'})
export class RollbarErrorHandler implements ErrorHandler {
  private previousErrors = [];

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private appEnvironment: AppEnvironmentService,
    private userApi: UserApi,
    private leadApi: LeadApi,
    private customerApi: CustomerApi,
    private portfolioApi: CustomerPortfolioApi
  ) {
    this.rollbar = this.initRollbar();
    this.appendDynamicData();
  }

  async handleError(err: any): Promise<void> {
    const error = err.originalError || err;

    if (this.previousErrors.find(e => isEqual(e, error))) {
      return;
    }

    this.rollbar.error(error);
    this.previousErrors.push(error);
  };

  private appendDynamicData() {
    this.userApi.me().subscribe((user) => {
      this.addOptionsToRollbar({payload: {person: {id: user?.id ?? 'anonymous'} as any}});
    });

    this.leadApi.lead(false).subscribe((lead) => {
      this.addOptionsToRollbar({payload: {person: {leadId: lead?.id} as any}});
    });

    this.portfolioApi.selected().subscribe((portfolio) => {
      if (isNil(portfolio)) {
        return;
      }
      this.addOptionsToRollbar(
        {payload: {person: {portfolioId: portfolio?.id, productId: portfolio.productId} as any}});
    });

    this.customerApi.customer(false).subscribe((customer) => {
      this.addOptionsToRollbar({payload: {person: {customerId: customer?.id} as any}});
    });
  }

  private addOptionsToRollbar(options: PropsOf<Rollbar.Configuration>): void {
    this.rollbar?.configure(merge(this.rollbar.options ?? {}, options ?? {}));
  }

  private emptyPersonData(): any {
    return {
      id: null,
      partner: this.appEnvironment.partnerName(),
      portfolioId: null,
      customerId: null,
      leadId: null,
      productId: null
    };
  }

  private initRollbar(): Rollbar {
    return new Rollbar(merge(this.appEnvironment.rollbar(), {
      maxTelemetryEvents: 50,
      scrubTelemetryInputs: true,
      overwriteScrubFields: true,
      payload: {
        person: this.emptyPersonData(),
        client: {
          javascript: {
            source_map_enabled: true,
            guess_uncaught_frames: true
          }
        }
      }
    }));
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function rollbarFactory() {
  return new Rollbar(merge(environment.rollbar, {
    maxTelemetryEvents: 50,
    scrubTelemetryInputs: true,
    overwriteScrubFields: true,
    payload: {
      person: {
        id: null,
        partner: environment.partner.name,
        portfolioId: null,
        customerId: null,
        leadId: null,
        productId: null
      },
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true
        }
      }
    }
  }));
}
