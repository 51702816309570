import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {Router} from 'src/app/infrastructure/router/router';
import {Modal} from 'src/app/infrastructure/service/modal.service';
import {WebPage} from 'src/app/ui/webapp/page/web.page';

@Component({
  selector: 'app-environment-modal',
  templateUrl: 'environment.modal.html'
})

export class EnvironmentModalPage extends WebPage {

  constructor(
    router: Router,
    private modalService: Modal,
    appEnvironment: AppEnvironmentService,
    titleService: Title
  ) {
    super(router, appEnvironment, titleService);
  }

  confirm(): void {
    this.modalService.dismiss(true);
  }
}
