import {Injectable} from '@angular/core';
import {Drivers, Storage} from '@ionic/storage';
import {defer, isEmpty} from 'lodash';
import {AUTH_NAMESPACE} from 'src/app/domain/store/auth.store';

const STORAGE_VERSION_KEY = '__storage-version__';
const STORAGE_VERSION_VALUE = '2';
@Injectable({
  providedIn: 'root',
})
export class Localstorage {
  private storage: Storage;
  private readonly protectedKeys: string[] = [
    AUTH_NAMESPACE, STORAGE_VERSION_KEY
  ];

  constructor(
  ) {
    this.storage = new Storage({
      name: '__finizens__',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });
    defer(async () => {
      await this.storage.create();
      const localStorageVersion = localStorage.getItem(STORAGE_VERSION_KEY);

      if (isEmpty(localStorageVersion) || localStorageVersion !== STORAGE_VERSION_VALUE) {
        const protectedStorage: {key: string; value: string}[] = [];
        this.protectedKeys.forEach((protectedKey) => {
          protectedStorage.push({key: protectedKey, value: localStorage.getItem(protectedKey)});
        });

        await localStorage.clear();

        protectedStorage.forEach((storageNamespace) => {
          if (!isEmpty(storageNamespace.value)) {
            localStorage.setItem(storageNamespace.key, storageNamespace.value);
          }
        });

        localStorage.setItem(STORAGE_VERSION_KEY, STORAGE_VERSION_VALUE);
      }
    });
  }

  async set(key: string, value: string): Promise<void> {
    await this.storage.set(key, value);
  }

  async get(key: string): Promise<string | null> {
    return this.storage.get(key);
  }

  syncSet(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  syncGet(key: string): string | null {
    const item = localStorage.getItem(key);
    if (item === 'undefined') {
      return null;
    }
    return item;
  }

  unset(key: string): void {
    localStorage.removeItem(key);
    this.storage.remove(key);
  }
}
