import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {isNil} from 'lodash';
import {emptyLogin, Login} from 'src/app/domain/command/auth.command';
import {Localstorage} from 'src/app/domain/service/storage/localstorage';

export interface LoginState {
  command: Login;
}

const emptyState = () => ({
  command: emptyLogin()
});

const LOGIN_PAGE_NAMESPACE = '__login_page__';

@Injectable({
  providedIn: 'root',
})
export default class LoginStore extends ComponentStore<LoginState> {

  constructor(private readonly localStorage: Localstorage) {
    super(emptyState());
  }

  public async initData(): Promise<void> {
    const data = await this.localStorage.get(LOGIN_PAGE_NAMESPACE);
    this.patchState( () => (isNil(data) ? emptyState() : {command: JSON.parse(data)}));
  }

  public save(username: string) {
    const command: Login = emptyLogin();
    command.username = username;

    this.patchState(() => ({
      command
    }));
    this.localStorage.set(LOGIN_PAGE_NAMESPACE, JSON.stringify(command));
  }

  public syncLogin(): Login {
    return this.get().command;
  }
}
