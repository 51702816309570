import {Injectable} from '@angular/core';
import {App} from '@capacitor/app';
import {Platform} from '@ionic/angular';
import {isNil} from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class Device {
  private applicationVersion: string | null = null;
  constructor(
    private readonly platform: Platform,
  ) {}

  public isApp(): boolean {
    return this.platform.is('capacitor');
  }

  public async appVersion(): Promise<string> {

    if (!isNil(this.applicationVersion)) {
      return this.applicationVersion;
    }

    if (!this.isApp()) {
      return '0.0.0';
    }

    const appInfo = await App.getInfo();
    this.applicationVersion = appInfo.version;
    return this.applicationVersion;
  }

  public isIosApp(): boolean {
    return this.platform.is('ios');
  }

  public isAndroidApp(): boolean {
    return this.platform.is('android');
  }

  public isMobile(): boolean {
    return this.platform.is('mobile');
  }

  public isMobileBrowser(): boolean {
    return this.platform.is('mobileweb');
  }
}
