import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {camelCase, isNil} from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {addParams, getTranslatableMsg, getTranslatableParams, toComplex, Translatable} from 'src/app/domain/service/translator/translatable';
import {AppEnvironmentService} from '../environment/app-environment/app-environment.service';

@Injectable({
  providedIn: 'root'
})
export class Translator {

  constructor(
    private translator: TranslateService,
    appEnv: AppEnvironmentService
  ) {
    this.translator.langs = appEnv.langs();
    this.translator.setDefaultLang(this.translator.langs[0]);
    const lang = this.translator.langs.includes(this.translator.getBrowserLang())
      ? this.translator.getBrowserLang()
      : this.translator.defaultLang;

    this.translator.use(lang);
  }

  public trans(translatable: Translatable, params?: object): string {
    translatable = addParams(translatable, params || {});
    const originalMsg = getTranslatableMsg(translatable);
    const parsedMsg = this.toCamelCase(getTranslatableMsg(translatable));
    const translated =  this.translator.instant(parsedMsg, getTranslatableParams(translatable));
    return translated === parsedMsg ? originalMsg : translated;
  }

  public asyncTrans(translatable: Translatable,  params?: object): Observable<string> {
    translatable = addParams(translatable, params || {});
    const originalMsg = getTranslatableMsg(translatable);
    const parsedMsg = this.toCamelCase(getTranslatableMsg(translatable));

    return this.translator.get(parsedMsg, getTranslatableParams(translatable))
      .pipe(map( translated => translated === parsedMsg ? originalMsg : translated));
  }

  public asyncArrayTrans(translatables: Translatable[], params?: object): Observable<string[]> {
    const parsedMsgs: string[] = translatables.map((translatable) => this.toCamelCase(getTranslatableMsg(translatable)));

    translatables.forEach((translatable) => {
      params = {...(params || {}), ...getTranslatableParams(translatable)};
    });
    return this.translator.get(parsedMsgs, params);
  }

  public async get(translatable: Translatable, params?: object): Promise<string|string[]|object> {
    translatable = addParams(translatable, params || {});
    const originalMsg = getTranslatableMsg(translatable);
    const parsedMsg = this.toCamelCase(getTranslatableMsg(translatable));
    const translated = await this.translator.get(parsedMsg, params).toPromise();
    return translated === parsedMsg ? originalMsg : translated;
  }

  public getLanguage(): string {
    return this.translator.currentLang;
  }

  public setLanguage(lang: string) {
    this.translator.use(lang);
  }

  toCamelCase(msg: string): string {
    if (msg.length === 1) {
      return msg.toLowerCase();
    }
    return msg.split('.').map((str) => camelCase(str)).join('.');
  }
}
