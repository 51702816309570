import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {App} from '@capacitor/app';
import {AppVersion} from 'src/app/domain/model/app/app-version.model';
import {Device} from 'src/app/infrastructure/device/device';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {Router} from 'src/app/infrastructure/router/router';
import {Modal} from 'src/app/infrastructure/service/modal.service';
import {WebPage} from 'src/app/ui/webapp/page/web.page';

@Component({
  selector: 'app-version-modal',
  templateUrl: 'app-version.modal.html'
})

export class AppVersionModalPage extends WebPage {
  public appVersion: AppVersion;
  constructor(
    private device: Device,
    private modalService: Modal,
    router: Router,
    appEnvironment: AppEnvironmentService,
    titleService: Title,
  ) {
    super(router, appEnvironment, titleService);
  }

  public confirm(): void {
    if (this.device.isIosApp()) {
      window.open(this.appEnvironment.appStoreLink('ios'), '_system');
    }
    if (this.device.isAndroidApp()) {
      window.open(this.appEnvironment.appStoreLink('android'), '_system');
    }
    App.exitApp();
  }

  public close(): void {
    if (!this.isRequiredUpdate()) {
      this.modalService.dismiss(true);
      return;
    }

    if (this.device.isIosApp()) {
      return;
    }

    App.exitApp(); // Only works on android
  }
  public isRequiredUpdate(): boolean {
    return this.appVersion.updateAction === 'force_update';
  }

  public isRecommendedUpdate(): boolean {
    return this.appVersion.updateAction === 'recommend_update';
  }

  public titleTransKey(): string {
    return this.isRecommendedUpdate() ? 'support.appUpdate.recommended.title' : 'support.appUpdate.required.title';
  }

  public description2TransKey(): string {
    return this.isRecommendedUpdate() ? 'support.appUpdate.recommended.description2' : 'support.appUpdate.required.description2';
  }

  public osLogo(): string {
    return this.device.isIosApp() ? 'logo-apple' : 'logo-google-playstore';
  }
  public showCloseButton(): boolean {
    return !this.device.isIosApp() || !this.isRequiredUpdate();
  }
}
