import {Component} from '@angular/core';
import {ButtonComponent} from 'src/app/ui/shared/component/button/button.component';

@Component({
  selector: 'app-filled-button',
  template: `
    <app-button
      bgColor="primary"
      bgHover="primary"
      textColor="white"
      textColorHover="white"
      [expand]="expand"
      [btnType]="btnType"
      [size]="size"
      [textStyle]="textStyle"
      [textSize]="textSize"
      [disabled]="disabled"
    >
      <ng-content></ng-content>
    </app-button>
  `,
})

export class FilledButtonComponent extends ButtonComponent {
}
