import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {AppVersionRequestCommand} from 'src/app/domain/command/app/app-version.command';
import {CreateCustomerFromLead, CreatePotentialAmount, CreateTolerance, UpdateCustomer} from 'src/app/domain/command/customer/customer.command';
import {UploadVat} from 'src/app/domain/command/vat.command';
import {toFormData} from 'src/app/domain/function/form-data.helper';
import {objKeysToSnakeCase} from 'src/app/domain/function/object.helper';
import {AppVersion} from 'src/app/domain/model/app/app-version.model';
import {Customer} from 'src/app/domain/model/customer/customer.model';
import AppVersionStore from 'src/app/domain/store/app/app-version.store';
import CustomerStore from 'src/app/domain/store/customer/customer.store';
import {ApiClient} from 'src/app/infrastructure/http/api.client';

@Injectable({
  providedIn: 'root',
})
export default class AppVersionApi {
  private readonly url = 'version/app-version';
  constructor(private http: ApiClient, private store: AppVersionStore) {
  }

  public async fetch(command: AppVersionRequestCommand): Promise<void> {
    const snakeCommand: any = objKeysToSnakeCase(command);
    const appVersion: AppVersion = await this.http.get(this.url, snakeCommand);
    this.store.save(appVersion);
  }

  public syncAppVersion(): AppVersion | null {
    return this.store.syncAppVersion();
  }

  public async waitFetchAndSync(command: AppVersionRequestCommand): Promise<AppVersion> {
    await this.fetch(command);
    return this.syncAppVersion();
  }
}
