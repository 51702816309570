import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {objToCamelCase} from '../../domain/function/object.helper';

@Injectable({
  providedIn: 'root',
})
export class CamelcaseInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((response: any) => {
        if (response.type !== 4) {return response;}
        const contentType = response.headers?.get('Content-Type')?.split(';')[0];
        if (contentType === 'application/json') {
          response.body = objToCamelCase(response.body??{});
        }

        return response;
      }),
    );
  }

}
