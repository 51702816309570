import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {UploadChildRelationshipProof} from 'src/app/domain/command/child/child.command';
import {CreateLeadCommand, UpdateLead} from 'src/app/domain/command/lead/lead.command';
import {UploadVat} from 'src/app/domain/command/vat.command';
import {toFormData} from 'src/app/domain/function/form-data.helper';
import {objKeysToSnakeCase} from 'src/app/domain/function/object.helper';
import {Lead} from 'src/app/domain/model/lead/lead.model';
import PartnerCommandHidratorService from 'src/app/domain/service/api/partner/partner-command-hidrator.api';
import LeadStore from 'src/app/domain/store/lead.store';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {ApiClient} from 'src/app/infrastructure/http/api.client';

@Injectable({
  providedIn: 'root',
})
export default class LeadApi {

  private readonly url = 'lead/customer';

  constructor(
    private api: ApiClient,
    private leadStore: LeadStore,
    private partnerCommandHidrator: PartnerCommandHidratorService,
  ) {
  }

  public lead(autoFetch = true): Observable<Lead> {
    return this.leadStore.lead$.pipe(
      tap(stored => {
        if (!stored && autoFetch) {
          this.fetch();
        }
      }),
      filter(stored => !!stored)
    );
  }

  public async fetch(): Promise<void> {
    const lead: Lead = await this.api.get(this.url);
    this.leadStore.saveLead(lead);
  }

  public async create(command: CreateLeadCommand): Promise<void> {
    if (AppEnvironmentService.isExternalPartner()) {
      command = this.partnerCommandHidrator.hidrateCreateLeadCommand(command);
    }
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.post(this.url, snakeCommand);
    await this.fetch();
  }

  public syncLead(): Lead | null {
    return this.leadStore.syncLead();
  }

  public async waitFetchAndSync(): Promise<Lead> {
    let lead = this.syncLead();
    if (!lead) {
      await this.fetch();
      lead = this.syncLead();
    }
    return lead;
  }

  public async update(command: UpdateLead): Promise<void> {
    if (AppEnvironmentService.isExternalPartner()) {
      command = this.partnerCommandHidrator.hidrateUpdateLeadCommand(command);
    }
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.post(this.url, snakeCommand);
    await this.fetch();
  }

  public async uploadVat(command: UploadVat): Promise<void> {
    const formData = toFormData(command);

    await this.api.post(this.url + '/vat', formData);
    await this.fetch();
  }

  public async uploadChildVat(command: UploadVat): Promise<void> {
    const formData = toFormData(command);

    await this.api.post('lead/child/vat', formData);
    await this.fetch();
  }

  public async uploadChildRelationshipProof(command: UploadChildRelationshipProof): Promise<void> {
    const formData = toFormData(command);

    await this.api.post('lead/child/relationship_proof', formData);
    await this.fetch();
  }
}
