import {isArray, isString} from 'lodash';

type SimpleTranslatable = string;
type ArrayTranslatable = [string, { [k: string]: any }?];

export type Translatable = SimpleTranslatable | ArrayTranslatable;

export const isSimple = (t: Translatable): boolean => isString(t);
export const isComplex = (t: Translatable): boolean => isArray(t);
export const toComplex = (t: Translatable): ArrayTranslatable => isSimple(t)
  ? [t as string, {}]
  : t as ArrayTranslatable;
export const addParams = (t: Translatable, params: { [k: string]: any }): ArrayTranslatable => {
  t = toComplex(t);
  t[1] = {...(t[1] || {}), ...params};
  return t;
};

export const getTranslatableParams = (t: Translatable): {[k: string]: any} => isSimple(t) ? {} : (t as ArrayTranslatable)[1] || {};
export const getTranslatableMsg = (t: Translatable): string => isSimple(t) ? t as SimpleTranslatable : (t as ArrayTranslatable)[0];
