import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {Lead} from '../model/lead/lead.model';
import {Localstorage} from '../service/storage/localstorage';

export interface LeadState {
  lead: Lead | null;
}

export const LEAD_NAMESPACE = '__lead__';

const emptyState = () => ({
  lead: null,
});

@Injectable({
  providedIn: 'root',
})
export default class LeadStore extends ComponentStore<LeadState> {
  public readonly lead$ = this.select(state => state.lead);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  saveLead(lead: Lead): void {
    this.patchState(() => ({
      lead
    }));
    this.localStorage.syncSet(LEAD_NAMESPACE, JSON.stringify(lead));
  }

  syncLead(): Lead | null {
    return this.get().lead;
  }

  reset(): void {
    this.setState(() => (emptyState()));
    this.localStorage.unset(LEAD_NAMESPACE);
  }
}
