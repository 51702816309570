import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {Plan} from '../model/plan/plan.model';

export interface PlanState {
  plan: Plan | null;
}

const emptyState = () => ({
  plan: null,
});

@Injectable({
  providedIn: 'root',
})
export default class PlanStore extends ComponentStore<PlanState> {
  public readonly plan$ = this.select(state => state.plan);

  constructor() {
    super(emptyState());
  }

  savePlan(plan: Plan): void {
    this.patchState(() => ({
      plan
    }));
  }

  syncPlan(): Plan | null {
    return this.get().plan;
  }

  reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));
  }
}
