import {Component, Input} from '@angular/core';
import {WebComponent} from '../../../webapp/component/web.component';
import {LogoColor} from './logo-color';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';

@Component({
  selector: 'app-logo',
  template: `
    <div class="h-full">
      <app-finizens-logo class="h-full" [premiumWhite]="premiumWhite" [color]="color" [footer]="footer" *ngIf="partner === 'finizens'"></app-finizens-logo>
      <app-evo-logo class="h-full" [color]="color" *ngIf="partner === 'evo'"></app-evo-logo>
      <app-caser-ul-logo class="h-full" [color]="color" *ngIf="partner === 'caser'"></app-caser-ul-logo>
    </div>
  `,
})
export class LogoComponent extends WebComponent {
  @Input() color: LogoColor = 'light';
  @Input() footer = false;
  @Input() premiumWhite = false;
  partner = 'finizens';

  constructor(
    private env: AppEnvironmentService
  ) {
    super();
  }

  protected onInit() {
    super.onInit();
    this.partner = this.env.partnerId();
  }
}
