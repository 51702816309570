import {Injectable} from '@angular/core';
import {Register} from 'src/app/domain/command/auth.command';
import CustomerApi from 'src/app/domain/service/api/customer/customer.api';
import PlanApi from 'src/app/domain/service/api/plan.api';
import UserApi from 'src/app/domain/service/api/user.api';
import {CustomerIoService} from 'src/app/infrastructure/analytics/customer-io.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackService {

  constructor(
    private planApi: PlanApi,
    private customerApi: CustomerApi,
    private userApi: UserApi,
    private customerIoService: CustomerIoService
  ) {
  }

  public identify(userId: string, data: object) {
    analytics.identify(userId,
      data
    );
  }
  public identifyCustomerIO(userId: string, data: object) {
    this.customerIoService.addCustomerIoScript(environment.analytics.customerIoSiteId, userId, data);
  }

  public track(eventName: string, data: object) {
    analytics.track(
      eventName,
      data
    );
  }

  async trackOnboarded() {
    const customer = await this.customerApi.waitFetchAndSync();
    const user = await this.userApi.waitFetchAndSync();

    /* eslint-disable @typescript-eslint/naming-convention */
    const traits = {
      first_name: customer.name,
      surname: customer.surname,
      email: customer.email,
      encoded_email: window.btoa(customer.email),
      created_at: user.registeredAt,
      fs_contact: true,
      onboarded_at: new Date().toLocaleString()
    };

    await analytics.track('Onboarded Done', traits);
  }

  async trackLogin(username: string) {
    analytics.track('New Login',
      {username}
    );
  }

  async trackRegister(registerCommand: Register) {
    analytics.track('New Lead',
      {
        first_name: registerCommand.firstName,
        surname: registerCommand.surname,
        email: registerCommand.email,
        encoded_email: window.btoa(registerCommand.email),
        invite_code: registerCommand.invitation
      }
    );
  }

  async trackCreatedContractPlan() {
    const plan = await this.planApi.waitFetchAndSync();

    analytics.track('Plan Created',
      {
        category: 'Plan',
        action: 'Plan Created',
        label: plan.planSettings.product[0].toUpperCase() + plan.planSettings.product.slice(1),
        value: plan.planSettings.initialAmount
      }
    );
  }

  async trackShareIntent(shareLink: string, email: string = null) {
    analytics.track('Share Intent',
      {
        link: shareLink,
        email,
        type: email ? 'email' : 'native'
      }
    );
  }

  async trackProductSelected(selectedProduct: string) {
    analytics.track('Product Selected', {
        product: selectedProduct
      }
    );
  }

  async trackPlanConfirmed(initialAmount: number, monthlyAmount: number, product: string) {
    const dataPlanObject = {
      initialAmount,
      monthlyAmount,
      product
    };

    this.track('Plan Confirmed', dataPlanObject);

    if (monthlyAmount) {
      this.track('Plan Confirmed With Monthly', dataPlanObject);
    } else {
      this.track('Plan Confirmed Without Monthly', dataPlanObject);
    }
  }

  async trackSimulationStarted() {
    this.track('Simulation Started', {});
  }

  async identifyUser(userId: string, data){
    this.identify(userId,data);
    this.identifyCustomerIO(userId,data);
  }
}
